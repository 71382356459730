import { ICustomer } from '../../../../../../../app/entities/Customer'
import { ICustomerVehicle } from '../../../../../../../app/entities/CustomerVehicle'
import { IInstallationPoint } from '../../../../../../../app/entities/InstallationPoint'
import { IOrderVehicle } from '../../../../../../../app/entities/OrderVehicle'
import { AddressComponent } from '../../../../../../../app/types/scheduling'
import { fetchData } from '../../../../../global/utils/fetch'
import {
  isValidEmail,
  isTenDigitPhoneNumber,
} from '../../../../../global/utils/formValidation'
import { ISchedulerStateProps } from '../container-modal/initialData'
import { IFormDataProps } from './CustomerInformation'
import {
  updateCustomer,
  updateCustomerVehicle,
  createCustomerVehicle,
  setCustomerVehicle,
  createInstallationPoint,
  setInstallationPoint,
  updateInstallationPoint,
} from './api'

export function checkFormFields(
  formData: IFormDataProps,
  shouldIgnoreContactInfo = false,
) {
  let passes = true

  for (const key in formData) {
    const element = formData[key]
    if (
      (key !== 'additionalInfo' &&
        key !== 'aptOrSuite' &&
        key !== 'brandId' &&
        key !== 'installationPointId' &&
        element === '') ||
      (!shouldIgnoreContactInfo && key === 'email' && !isValidEmail(element)) ||
      (!shouldIgnoreContactInfo &&
        key === 'phone' &&
        !isTenDigitPhoneNumber(element))
    ) {
      passes = false
    }
  }

  return passes
}

const updateCustomerPromise = async (
  formData: IFormDataProps,
  custId: string,
) => {
  const body = {
    objectId: custId,
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    phoneNumber: formData.phone,
  }

  try {
    await fetchData<ICustomer>(updateCustomer(body))
  } catch (error) {
    throw error
  }
}

const updateCustomerVehiclePromise = async (
  formData: IFormDataProps,
  customerVehicleId: string,
  customerVehicleBrandId: string,
  orderVehicleId: string,
) => {
  const body = {
    brand: {
      name: formData.make,
      objectId: customerVehicleBrandId,
    },
    customerVehicleId: customerVehicleId,
    model: formData.model,
    objectId: customerVehicleId,
    orderVehicleId: orderVehicleId,
    variant: formData.variant,
    product: null,
    year: formData.year,
  }

  try {
    await fetchData<ICustomerVehicle>(updateCustomerVehicle(body))
  } catch (error) {
    throw error
  }
}
const createCustomerVehiclePromise = async (
  formData: IFormDataProps,
  customerId: string,
) => {
  const body = {
    variant: formData.variant,
    year: formData.year,
    model: formData.model,
    brand: {
      objectId: formData.brandId,
      name: formData.make,
    },
  }
  try {
    const res = await fetchData<ICustomerVehicle>(
      createCustomerVehicle(body, customerId),
    )
    return res
  } catch (error) {
    throw error
  }
}
const setCustomerVehiclePromise = async (
  orderId: string,
  vehicleObjectId: string,
) => {
  try {
    await fetchData<IOrderVehicle[]>(
      setCustomerVehicle(orderId, vehicleObjectId),
    )
  } catch (error) {
    throw error
  }
}

const createInstallationPointPromise = async (
  formData: IFormDataProps,
  orderId: string,
) => {
  const body = {
    zipCode: formData.zipCode,
    address: formData.address,
    additionalInformation: formData.additionalInfo,
    aptOrSuite: formData.aptOrSuite,
    city: formData.city,
    state: formData.state,
  }

  try {
    const installationPoint = await fetchData<IInstallationPoint>(
      createInstallationPoint(body),
    )
    await fetchData<IInstallationPoint>(
      setInstallationPoint(installationPoint, orderId),
    )
  } catch (error) {
    throw error
  }
}
export const updateInstallationPointPromise = async (
  formData: IFormDataProps,
) => {
  const body = {
    zipCode: formData.zipCode,
    address: formData.address,
    additionalInformation: formData.additionalInfo,
    aptOrSuite: formData.aptOrSuite,
    city: formData.city,
    state: formData.state,
    objectId: formData.installationPointId,
  }

  try {
    await fetchData<IInstallationPoint>(updateInstallationPoint(body))
  } catch (error) {
    throw error
  }
}

export async function handleWithoutCustomerVehicleId(
  customerId: string,
  formData: IFormDataProps,
  orderId: string,
  setSchedulerState: Function,
  shouldSaveCustomer: boolean,
) {
  try {
    const createCustomerVehicleResponse = await createCustomerVehiclePromise(
      formData,
      customerId,
    )
    setSchedulerState((prevState: ISchedulerStateProps) => ({
      ...prevState,
      orderVehicleId: createCustomerVehicleResponse.objectId,
    }))

    const promiseArray = [
      setCustomerVehiclePromise(
        orderId,
        createCustomerVehicleResponse.objectId,
      ),
    ]

    if (shouldSaveCustomer) {
      promiseArray.push(updateCustomerPromise(formData, customerId))
    }

    if (formData.installationPointId) {
      // this should go away since we have a different flow for updating installation point
      promiseArray.push(updateInstallationPointPromise(formData))
    } else {
      promiseArray.push(createInstallationPointPromise(formData, orderId))
    }
    await Promise.all(promiseArray)
  } catch (error) {
    throw error
  }
}

export async function handleWithCustomerVehicleId(
  customerId: string,
  customerVehicleId: string,
  formData: IFormDataProps,
  orderId: string,
  orderVehicleId: string,
  shouldSaveCustomer: boolean,
) {
  try {
    const promiseArray = [
      updateCustomerVehiclePromise(
        formData,
        customerVehicleId,
        formData.brandId,
        orderVehicleId,
      ),
    ]

    if (shouldSaveCustomer) {
      promiseArray.push(updateCustomerPromise(formData, customerId))
    }

    if (formData.installationPointId) {
      // this should go away since we have a different flow for updating installation point
      promiseArray.push(updateInstallationPointPromise(formData))
    } else {
      promiseArray.push(createInstallationPointPromise(formData, orderId))
    }

    await Promise.all(promiseArray)
  } catch (error) {
    throw error
  }
}

const COMPONENT_TYPE_MAP = {
  zipCode: 'postal_code',
  address: ['street_number', 'route'],
  city: 'locality',
  state: 'administrative_area_level_1',
} as { [key: string]: string | string[] }

export function determineDifferingAddressFields(
  googleAddressComponents: AddressComponent[],
  inputtedAddress: IFormDataProps,
) {
  const differingFields = Object.keys(COMPONENT_TYPE_MAP).filter((key) => {
    const googleField = COMPONENT_TYPE_MAP[key]
    const isArray = Array.isArray(googleField)

    let googleValue = ''
    if (isArray) {
      const googleValues = googleField
        .map((field) => {
          const component = googleAddressComponents.find(
            (comp) => comp.componentType === field,
          )
          return component
        })
        .filter(Boolean)
        .filter((comp) => comp?.replaced)

      // if none have been replaced, then we don't have a differing field
      if (googleValues.length === 0) {
        return false
      }

      googleValue = googleValues
        .map((comp) => comp?.componentName.text)
        .join(' ')
    } else {
      const component = googleAddressComponents.find(
        (comp) => comp.componentType === googleField,
      )
      if (!component) {
        return false
      }
      googleValue = component.componentName.text
    }

    return inputtedAddress[key] !== googleValue
  })

  return differingFields
}
