import { OrderService } from '../../../../../../../../app/entities/OrderService'
import {
  SERVICE_NAMES,
  PRODUCT_SERVICE_NAMES,
} from '../../../../../../global/constants/service'
import {
  ISchedulerStateProps,
  ITiresProps,
} from '../../../../../../schedule/components/schedule-modal/components/container-modal/initialData'
import { IFormDataProps } from '../../../../../../schedule/components/schedule-modal/components/customer-information/CustomerInformation'

function setTires(tiresCombination: any, initialTireProps: ITiresProps) {
  initialTireProps.objectId =
    tiresCombination.objectId ?? initialTireProps.objectId
  initialTireProps.frontCount =
    tiresCombination.frontCount ?? initialTireProps.frontCount
  initialTireProps.rearCount =
    tiresCombination.rearCount ?? initialTireProps.rearCount
  if (tiresCombination.frontTire) {
    const {
      aspectRatio,
      diameter,
      width,
      objectId,
      psi,
      manufacturer,
      model,
      torque,
    } = tiresCombination.frontTire
    initialTireProps.frontTire.objectId = objectId
    initialTireProps.frontTire.aspectRatio =
      aspectRatio?.toString() ?? initialTireProps.frontTire.aspectRatio
    initialTireProps.frontTire.diameter =
      diameter?.toString() ?? initialTireProps.frontTire.diameter
    initialTireProps.frontTire.width = width ?? initialTireProps.frontTire.width
    initialTireProps.frontTire.psi = psi ?? initialTireProps.frontTire.psi
    initialTireProps.frontTire.manufacturer =
      manufacturer ?? initialTireProps.frontTire.manufacturer
    initialTireProps.frontTire.model = model ?? initialTireProps.frontTire.model
    initialTireProps.frontTire.torque =
      torque ?? initialTireProps.frontTire.torque
  }

  if (tiresCombination.rearTire) {
    const {
      aspectRatio,
      diameter,
      width,
      objectId,
      psi,
      manufacturer,
      model,
      torque,
    } = tiresCombination.rearTire
    initialTireProps.rearTire.objectId = objectId
    initialTireProps.rearTire.aspectRatio =
      aspectRatio?.toString() ?? initialTireProps.rearTire.aspectRatio
    initialTireProps.rearTire.diameter =
      diameter?.toString() ?? initialTireProps.rearTire.diameter
    initialTireProps.rearTire.width = width ?? initialTireProps.rearTire.width
    initialTireProps.rearTire.psi = psi ?? initialTireProps.rearTire.psi
    initialTireProps.rearTire.manufacturer =
      manufacturer ?? initialTireProps.rearTire.manufacturer
    initialTireProps.rearTire.model = model ?? initialTireProps.rearTire.model
    initialTireProps.rearTire.torque =
      torque ?? initialTireProps.rearTire.torque
  }

  return initialTireProps
}

function transformOrderData(data: any) {
  let freeOrder = {
    reason: '',
    objectId: '',
    isFree: false,
  }
  let customerInfo: IFormDataProps = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    aptOrSuite: '',
    city: '',
    state: '',
    zipCode: '',
    additionalInfo: '',
    make: '',
    model: '',
    year: '',
    variant: '',
    brandId: '',
    mileage: null,
    installationPointId: '',
  }
  let customerVehicleId = ''
  let customerVehicleBrandId = ''
  let customerId = ''
  let orderVehicleObjectId = ''
  let tireDisposal = false
  let disposalQty = 0
  let selectedService = ''
  let hasMinimumServiceCharge = false
  let orderServiceObjectId = ''
  let initialTireProps: ITiresProps = {
    objectId: '',
    frontCount: 0,
    rearCount: 0,
    frontTire: {
      objectId: '',
      aspectRatio: '',
      width: '',
      diameter: '',
      psi: null,
      manufacturer: '',
      model: '',
      torque: null,
    },
    rearTire: {
      objectId: '',
      aspectRatio: '',
      width: '',
      diameter: '',
      psi: null,
      manufacturer: '',
      model: '',
      torque: null,
    },
  }

  const {
    installationPoint,
    customer,
    orderVehicles,
    orderServices,
    freeOrderReason,
  }: any = data
  if (installationPoint) {
    customerInfo.address = installationPoint.address ?? customerInfo.address
    customerInfo.zipCode = installationPoint.zipCode ?? customerInfo.zipCode
    customerInfo.city = installationPoint.city ?? customerInfo.city
    customerInfo.state = installationPoint.state ?? customerInfo.state
    customerInfo.additionalInfo =
      installationPoint.additionalInformation ?? customerInfo.additionalInfo
    customerInfo.installationPointId =
      installationPoint.objectId ?? customerInfo.installationPointId
  }

  const genericItems = [] as OrderService[]

  let stateTireFee: undefined | OrderService = undefined
  let servicePriceOverride: undefined | number = undefined

  if (orderServices) {
    orderServices.forEach((orderService: OrderService) => {
      if (orderService.label === SERVICE_NAMES.TIRE_DISPOSAL) {
        tireDisposal = true
        disposalQty = orderService.quantity
      } else if (orderService.label === SERVICE_NAMES.STATE_TIRE_FEE) {
        stateTireFee = orderService
      } else if (
        PRODUCT_SERVICE_NAMES.includes(orderService.service?.name ?? '') //this will include Products and Generic Tires
      ) {
        genericItems.push(orderService)
      } else if (orderService.label === SERVICE_NAMES.MINIMUM_SERVICE_CHARGE) {
        hasMinimumServiceCharge = true
      } else {
        selectedService = orderService.service?.objectId ?? selectedService
        orderServiceObjectId = orderService.objectId

        if (
          orderService.priceOverride !== undefined &&
          orderService.priceOverride !== null
        ) {
          servicePriceOverride = orderService.priceOverride
        }
      }
    })
  }

  if (freeOrderReason) {
    freeOrder.objectId = freeOrderReason.objectId ?? freeOrder.objectId
    freeOrder.reason = freeOrderReason.reason ?? freeOrder.reason
    freeOrder.isFree = true
  }

  if (customer) {
    customerId = customer.objectId
    customerInfo.firstName = customer.firstName ?? customerInfo.firstName
    customerInfo.lastName = customer.lastName ?? customerInfo.lastName
    customerInfo.email = customer.email ?? customerInfo.email
    customerInfo.phone = customer.phoneNumber ?? customerInfo.phone
  }

  if (orderVehicles) {
    let vehicle: any = null
    orderVehicles.forEach((el: any) => {
      if (el.customerVehicle && el.customerVehicle.brand) {
        orderVehicleObjectId = el.objectId
        customerInfo.mileage = el.mileage ?? customerInfo.mileage
        vehicle = el.customerVehicle
        if (el.tiresCombination) {
          initialTireProps = setTires(el.tiresCombination, initialTireProps)
        }
      }
    })

    if (vehicle) {
      const { brand }: any = vehicle
      customerVehicleBrandId = brand.objectId
      customerVehicleId = vehicle.objectId
      customerInfo.make = brand.name ?? customerInfo.make
      customerInfo.year = vehicle.year ?? customerInfo.year
      customerInfo.model = vehicle.model ?? customerInfo.model
      customerInfo.variant = vehicle.variant ?? customerInfo.variant
      customerInfo.brandId = brand.objectId
    }
  }

  return {
    customerInfo,
    customerVehicleBrandId,
    customerVehicleId,
    customerId,
    orderVehicleObjectId,
    tireDisposal,
    initialTireProps,
    freeOrder,
    disposalQty,
    selectedService,
    orderService: orderServiceObjectId,
    genericItems,
    stateTireFee,
    servicePriceOverride,
    hasMinimumServiceCharge,
  }
}

export default function setOrderState(data: any) {
  const {
    customerInfo,
    customerVehicleBrandId,
    customerVehicleId,
    customerId,
    orderVehicleObjectId,
    tireDisposal,
    initialTireProps,
    freeOrder,
    disposalQty,
    selectedService,
    orderService,
    genericItems,
    stateTireFee,
    servicePriceOverride,
  } = transformOrderData(data)

  const orderState: ISchedulerStateProps = {
    customerInformation: customerInfo,
    freeOrderReason: freeOrder,
    orderId: data.objectId,
    orderVehicleId: orderVehicleObjectId,
    tireDisposal: tireDisposal,
    disposalQty: disposalQty,
    tires: initialTireProps,
    customerVehicleId: customerVehicleId,
    customerVehicleBrandId: customerVehicleBrandId,
    customerId: customerId,
    status: data.status,
    service: selectedService,
    orderService: orderService,
    selectedAvailabilityCalendarSlot: undefined,
    shouldRequestPaymentAfterScheduling: false,
    genericItems,
    stateTireFee,
    servicePriceOverride,
  }
  return orderState
}
