import { formatDateYyyyMmDd } from '../../../global/utils/date/date-utils-yyyy-mm-dd'

export function getFuturePercentBookedData(date: Date, csv?: boolean) {
  const url = new URL(
    `${process.env.REACT_APP_ORIGIN}/reportsV2/future-percent-booked/`,
  )

  const dateParam = formatDateYyyyMmDd(date)
  const params = new URLSearchParams({ date: dateParam })

  if (csv) {
    params.append('csv', 'true')
  }

  url.search = params.toString()

  return {
    method: 'GET',
    url: url.toString(),
  }
}
