export function getRoles(getPermissions: boolean) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/roles?getPermissions=${getPermissions}`,
  }
}
export function getDoesRoleRequireLocation(roleObjectId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/roles/${roleObjectId}/requires-location`,
  }
}
