import determineIsVinValid from '../../../../global/utils/determine-is-vin-valid'

const ERR_INVALID_VIN =
  'VIN must be exactly 17 characters long and contain only valid characters (0-9, A-H, J-N, P, R-Z).'

function handleVinChange(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setVanData: Function,
  setVinError: Function,
) {
  const upperCaseValue = e.target.value.toUpperCase() // Convert to uppercase to standardize
  setVanData({ vin: upperCaseValue })

  const isVinValid =
    determineIsVinValid(upperCaseValue) || upperCaseValue === ''
  if (isVinValid) {
    setVinError('')
  } else {
    setVinError(ERR_INVALID_VIN)
  }
}

export default handleVinChange
