import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import AddressSearchWithTireQuant from '../address-search/AddressSearchWithTireQuantity'
import {
  ISchedulerStateProps,
  initialStateProps,
} from '../../../schedule/components/schedule-modal/components/container-modal/initialData'
import AvailableAppointments from '../next-available-appointments/NextAvailableAppointments'

function AvailabilityByAddress() {
  const [isZipCodeServiced, setIsZipCodeServiced] = useState<any>(undefined)
  const [isCalendarVisible, setIsCalendarVisible] = useState(false)
  const [schedulerState, setSchedulerState] = useState<ISchedulerStateProps>({
    ...initialStateProps,
  })

  function displayAvailabilityCalendar() {
    setIsCalendarVisible(true)
  }

  useEffect(() => {
    if (!isZipCodeServiced) {
      setIsCalendarVisible(false)
    }
  }, [isZipCodeServiced])

  return (
    <div className={styles.mainContainer}>
      <AddressSearchWithTireQuant
        isZipCodeServiced={isZipCodeServiced}
        setIsZipCodeServiced={setIsZipCodeServiced}
        displayAvailabilityCalendar={displayAvailabilityCalendar}
        schedulerState={schedulerState}
        setSchedulerState={setSchedulerState}
      />
      <AvailableAppointments
        schedulerState={schedulerState}
        setSchedulerState={setSchedulerState}
        isCalendarVisible={isCalendarVisible}
      />
    </div>
  )
}

export default AvailabilityByAddress
