import TextField from '@mui/material/TextField'
import { useState, ChangeEvent, useEffect, useRef } from 'react'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import LocationDropDown from '../../../../../global/components/location-drop-down/LocationDropDown'
import styles from './styles.module.css'
import TechDropDown from './TechDropDown'
import { setRouteAndRelations } from '../../../api'
import { fetchData } from '../../../../../global/utils/fetch'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { IRoute } from '../../../../../../../app/entities/Route'
import { formatDateYyyyMmDd } from '../../../../../global/utils/date/date-utils-yyyy-mm-dd'

export interface IRouteBuilderFormProps {
  locationId: string
  technicianId: string
  date: string
}

export default function RouteBuilder() {
  const { user } = useAuthenticator((context) => [context.user])
  const [routeBuilderFormInfo, setRouteBuilderFormInfo] =
    useState<IRouteBuilderFormProps>({
      locationId: '',
      technicianId: '',
      date: '',
    })
  const [isLoading, setIsLoading] = useState(false)

  const handleLocationChange = (newValue: string) => {
    setRouteBuilderFormInfo({ ...routeBuilderFormInfo, locationId: newValue })
  }

  const handleTechChange = (newValue: string) => {
    setRouteBuilderFormInfo({ ...routeBuilderFormInfo, technicianId: newValue })
  }

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRouteBuilderFormInfo({ ...routeBuilderFormInfo, date: e.target.value })
  }

  const buildRouteHandler = async () => {
    try {
      setIsLoading(true)
      await fetchData<IRoute>(setRouteAndRelations(routeBuilderFormInfo))
      alert('Route Built!')
    } catch (err) {
      console.error(err)
      alert(err)
    } finally {
      setIsLoading(false)
    }
  }
  const previousLocationId = useRef(routeBuilderFormInfo.locationId)

  useEffect(() => {
    //clears form fields if locationId is changed
    if (previousLocationId.current !== routeBuilderFormInfo.locationId) {
      setRouteBuilderFormInfo({
        locationId: routeBuilderFormInfo.locationId,
        technicianId: '',
        date: '',
      })
      previousLocationId.current = routeBuilderFormInfo.locationId
    }
  }, [routeBuilderFormInfo.locationId])

  const today = new Date()
  let maxDate = new Date()

  if (today.getUTCHours() >= 19 || today.getUTCHours() < 4) {
    // If it's between 3:00pm EST (7pm UTC, or 19:00 UTC) and 11:59:59 EST (04:00 UTC), set maxDate to tomorrow
    maxDate.setDate(today.getDate() + 1)
  }
  const formattedMinDate = formatDateYyyyMmDd(today)
  const formattedMaxDate = formatDateYyyyMmDd(maxDate)
  return (
    <div className={styles.routeBuilderContainer}>
      <div className={styles.title}>Route Builder</div>
      <div className={styles.inputs}>
        <LocationDropDown onLocationChange={handleLocationChange} />
        <TechDropDown
          location={routeBuilderFormInfo.locationId}
          onTechChange={handleTechChange}
        />
        <TextField
          type='date'
          label='date'
          InputLabelProps={{
            shrink: true,
          }}
          value={routeBuilderFormInfo.date}
          onChange={handleDateChange}
          InputProps={{
            inputProps: { min: formattedMinDate, max: formattedMaxDate },
          }}
        />
        <PrimaryButton
          buttonName='Build Route'
          disabled={
            !routeBuilderFormInfo.locationId ||
            !routeBuilderFormInfo.technicianId ||
            routeBuilderFormInfo.date === '' ||
            isLoading
          }
          onClick={buildRouteHandler}
        />
      </div>
      <div className={styles.note}>
        NOTE: All orders for a location will be bumped during Route Optimization
        if a Route is made for that location before Route Optimization. Example:
        if at noon today, a Route is made in South Bend for tomorrow, when Route
        Optimization runs for South Bend at 3 pm, all Orders in South Bend for
        tomorrow will be bumped to pending_rescheduling. However, after Route
        Optimization runs at 3 pm, Routes can be safely created for tomorrow.
      </div>
    </div>
  )
}
