export const updateBrainTreeToken = (orderObjectId: string, newBraintreeToken: string) => {
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/work_order_utility/update-braintree-token`,
		body: {
			orderObjectId,
			newBraintreeToken,
		},
	}
}
