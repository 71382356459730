import { IInstallationPoint } from '../../../../../../../app/entities/InstallationPoint'
import { ISchedulerStateProps } from '../container-modal/initialData'

export function overwriteOrderNote(orderId: string, note: string) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/note`,
    body: note,
  }
}
export function scheduleAppointment(state: ISchedulerStateProps) {
  const {
    customerInformation,
    tires,
    selectedAvailabilityCalendarSlot,
    service,
  } = state

  if (!selectedAvailabilityCalendarSlot)
    throw new Error('Missing required selectedAvailabilityCalendarSlot')

  const { timeSlot } = selectedAvailabilityCalendarSlot

  if (!timeSlot) throw new Error('Missing required timeSlot')

  return {
    method: 'POST',
    body: {
      appointment: {
        customer: {
          firstName: customerInformation.firstName,
          lastName: customerInformation.lastName,
          email: customerInformation.email,
          phoneNumber: customerInformation.phone,
        },
        installationPoint: {
          address: customerInformation.address,
          ...(customerInformation.aptOrSuite.trim() && {
            aptOrSuite: customerInformation.aptOrSuite,
          }),
          city: customerInformation.city,
          state: customerInformation.state,
          zipCode: customerInformation.zipCode,
          additionalInformation: customerInformation.additionalInfo,
        } as IInstallationPoint,
        vehicles: [
          {
            variant: customerInformation.variant,
            year: customerInformation.year,
            model: {
              name: customerInformation.model,
              brand: {
                name: customerInformation.make,
              },
            },
            tires: tires,
            mileage: customerInformation.mileage,
          },
        ],
        serviceObjectId: service,
        genericItems: state.genericItems,
        servicePriceOverride: state.servicePriceOverride,
      },
      timeSlot,
    },
    url: `${process.env.REACT_APP_ORIGIN}/scheduling/schedule?source=customer_support`,
  }
}
export function getOrderVehicleByOrderId(orderId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/order_vehicles/byOrderObjectId??orderObjectId=${orderId}`,
  }
}
export function getOrderByObjectIdNoAuth(objectId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/by-object-id/${objectId}/no-auth`,
  }
}
export function setTimeSlot(body: any, orderId: string) {
  return {
    method: 'POST',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/time-slot`,
  }
}
export function setTimeSlotNoAuth(body: any, orderId: string) {
  return {
    method: 'POST',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/time-slot/no-auth`,
  }
}
export function getCurrentRescheduleReason(orderId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/reschedule-reason`,
  }
}
export function scheduledToPending(body: any) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/work_order_utility/scheduled-to-pending`,
    body,
  }
}
export function rescheduleAppointment(
  body: any,
  orderId: string,
  reason?: string,
  username?: string,
) {
  const params = new URLSearchParams()
  if (reason) params.append('reason', reason)
  if (username) params.append('username', username)
  return {
    method: 'POST',
    body: body,
    url:
      `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/reschedule` +
      (params.toString() ? `?${params.toString()}` : ''),
    returnRaw: true,
  }
}
