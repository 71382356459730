import { useState, useEffect } from 'react'
import { refreshToken } from '../utils/refreshToken'
import { useNavigate } from 'react-router-dom'
import { routesThatShouldNotRedirectDueToMissingAuth } from '../../base/components/base-container/BaseContainer'

interface IApiProps {
  method: string
  url: string
  body?: any
}

interface IApiState<R> {
  data: R | null
  isLoading: boolean
  error: any | null
}

const NO_CURRENT_USER_ERROR = 'No current user'

export default function useFetch<R>({ method, url, body }: IApiProps) {
  const navigate = useNavigate()
  const location = window.location
  const [response, setResponse] = useState<IApiState<R>>({
    data: null,
    isLoading: true,
    error: null,
  })
  const [refetchIndex, setRefetchIndex] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      setResponse({ ...response, isLoading: true })

      const shouldRedirectToLoginOnNoCurrentUser =
        !routesThatShouldNotRedirectDueToMissingAuth.some((route) =>
          route.test(location.pathname),
        )

      try {
        const idToken = await refreshToken()

        const headers = {
          'Content-Type': 'application/json',
          Authorization: idToken ? `Bearer ${idToken}` : '',
        }

        const resp = await fetch(url, {
          method: method,
          headers,
          body: JSON.stringify(body),
        })

        if (resp.status === 204) {
          setResponse((prevState) => ({
            ...prevState,
            data: [] as unknown as R,
            isLoading: false,
          }))
          return
        }
        const data = (await resp.json()) as R
        if (!resp.ok) {
          throw new Error(`Request failed with status ${resp.status}`)
        }
        setResponse({ ...response, data, isLoading: false })
      } catch (error: any) {
        if (error === NO_CURRENT_USER_ERROR) {
          if (shouldRedirectToLoginOnNoCurrentUser) {
            navigate('/login')
          }
          return
        } else {
          setResponse({ ...response, error, isLoading: false })
        }
      }
    }
    fetchData()
  }, [url, method, body, refetchIndex])

  const refetch = () => {
    setRefetchIndex(refetchIndex + 1)
  }

  return { ...response, refetch }
}
