import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { MouseEventHandler, useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import NewVanFormComponent from '../van-form-component/new/NewVanFormComponent'
import SecondaryButton from '../../../global/components/buttons/secondary-button/SecondaryButton'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import { IVan } from '../../../../../app/entities/Van'
import { fetchData } from '../../../global/utils/fetch'
import { createVan } from '../../api'
import { ToastNotificationContext } from '../../../global/context/toast-context/ToastNotificationContext'
import CircularProgress from '@mui/material/CircularProgress'
import { IRefreshCounterProps } from '../../main/VansMain'
import { VanContext } from '../../../global/context/van-context/VanContext'
import parseForValidationError from '../../../global/utils/error/parse-for-validation-error'

interface INewVanModalProps {
  isOpen: boolean
  handleClosed: Function
  refresh: IRefreshCounterProps
}

function NewVanModal(props: INewVanModalProps) {
  const { vanData } = useContext(VanContext)

  const { isOpen, handleClosed } = props
  const { refreshCounter, setRefreshCounter } = props.refresh
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFormDataValid, setIsFormDataValid] = useState<boolean>(true)
  const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(
    determineIsCreateDisabled,
  )
  const [operatingStatusNote, setOperatingStatusNote] = useState<string>('')

  function determineIsCreateDisabled() {
    return isLoading || !isFormDataValid
  }
  useEffect(() => {
    setIsCreateDisabled(determineIsCreateDisabled)
  }, [isFormDataValid, isLoading])

  async function handleCreateVan(e: any) {
    try {
      setIsLoading(true)
      const van = await fetchData<IVan>(createVan(vanData, operatingStatusNote))
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Successfully created van!',
        severity: 'success',
      })
      setRefreshCounter(() => refreshCounter + 1)

      handleClosed(e)
    } catch (error: any) {
      console.error('Error creating van:', error)

      const match = parseForValidationError(error.message)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `Error creating Van${match ? `: ${match}` : ''}`,
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={styles.newVanModal}>
          <div className={styles.contentContainer}>
            <div className={`${styles.newVanModalPageTitle} font--bold`}>
              Create New Van
            </div>
            <NewVanFormComponent
              operatingStatusNote={operatingStatusNote}
              setIsFormDataValid={setIsFormDataValid}
              setOperatingStatusNote={setOperatingStatusNote}
            />
            {isLoading && (
              <div className={styles.progressContainer}>
                <CircularProgress />
              </div>
            )}
            <div className={styles.buttonContainer}>
              <SecondaryButton
                buttonName='Cancel'
                onClick={handleClosed as MouseEventHandler}
              />
              <PrimaryButton
                buttonName='Create'
                onClick={handleCreateVan}
                disabled={isCreateDisabled}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default NewVanModal
