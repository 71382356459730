import { TextField } from '@mui/material'
import styles from '../../styles.module.css'
import { useEffect, useState } from 'react'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import { fetchData } from '../../../../global/utils/fetch'
import { setTireRackOrderNoAuth } from '../../../api'
import { IOrder } from '../../../../../../app/entities/Order'
import { IAlertStatusProps } from '../../../../global/components/toast-alert/ToastNotification'

interface EditTireRackOrderProps {
  alertStatus: IAlertStatusProps
  handleClose: Function
  isOpen: boolean
  orderObjectId: string
  setAlertStatus: Function
}

export default function EditTireRackOrder(props: EditTireRackOrderProps) {
  const { alertStatus, handleClose, isOpen, orderObjectId, setAlertStatus } =
    props

  const [isConfirmedDisabled, setIsConfirmedDisabled] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [formData, setFormData] = useState({
    tireRackOrderNumber: '',
  })

  async function handleSetTireRackOrder() {
    try {
      setIsLoading(true)
      await fetchData<IOrder>(
        setTireRackOrderNoAuth(orderObjectId, formData.tireRackOrderNumber),
      )
      setAlertStatus({
        ...alertStatus,
        isOpen: true,
        message: 'Successfully added Tire Rack Order number to the Order!',
        severity: 'success',
      })
      handleClose()
    } catch (err) {
      console.error(err)
      setAlertStatus({
        ...alertStatus,
        isOpen: true,
        message: 'Could not find the provided Tire Rack Order number!',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsConfirmedDisabled(formData.tireRackOrderNumber.length !== 7)
  }, [formData])

  return (
    <>
      {isOpen && (
        <div className={styles.inputsContainer}>
          <div className={styles.subModalHeader}>
            Edit Tire Rack Order Number
          </div>
          <div className={styles.checkBoxContainer}>
            <div>Please enter your Tire Rack Order Number.</div>
          </div>
          <div className={styles.tireRackOrderNumberInputContainer}>
            <TextField
              className={styles.tireRackOrderNumberInput}
              label='Tire Rack Order Number'
              value={formData.tireRackOrderNumber}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  tireRackOrderNumber: e.target.value.replace(
                    /[^a-zA-Z0-9]/g,
                    '',
                  ),
                }))
              }
            />
          </div>
          <div className={styles.buttonContainer}>
            <SecondaryButton
              buttonName='Cancel'
              onClick={() => {
                handleClose()
              }}
            />
            <PrimaryButton
              buttonName='Confirm'
              disabled={isConfirmedDisabled || isLoading}
              onClick={handleSetTireRackOrder}
            />
          </div>
        </div>
      )}
    </>
  )
}
