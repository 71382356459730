import { ISchedulerStateProps } from '../../../../schedule/components/schedule-modal/components/container-modal/initialData'
interface ICustomerInformationComponentProps {
  schedulerState: ISchedulerStateProps
}

export default function CustomerInfo(
  props: ICustomerInformationComponentProps,
) {
  const { schedulerState } = props
  const { customerInformation } = schedulerState
  const { firstName, lastName, make, model, year, email, phone } =
    customerInformation

  return (
    <div>
      <div style={{ fontWeight: 600 }}>Appointment Info:</div>
      <div>{`${firstName} ${lastName}`}</div>
      <div>{`${year} ${make} ${model}`}</div>
      <div>{phone}</div>
      <div>{email}</div>
    </div>
  )
}
