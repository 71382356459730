import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import LocationDropDown from '../../../../global/components/location-drop-down/LocationDropDown'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import { fetchData } from '../../../../global/utils/fetch'
import { getReport } from '../../api'
import { isValidEmail } from '../../../../global/utils/formValidation'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment'
import { formatDateYyyyMmDd } from '../../../../global/utils/date/date-utils-yyyy-mm-dd'

interface IScheduleModalProps {
  isOpen: boolean
  handleClosed: Function
  reportName: string
}

interface FormData {
  fromDate: Date | null
  toDate: Date | null
  location: string
  email: string
}

export default function ExportReportToEmailModal(props: IScheduleModalProps) {
  const [formData, setFormData] = useState<FormData>({
    fromDate: null,
    toDate: null,
    location: '',
    email: '',
  })
  const [validEmail, setValidEmail] = useState<any>(null)
  const [successMessage, setSuccessMessage] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [isToDateDisabled, setIsToDateDisabled] = useState(true)
  const [minToDate, setMinToDate] = useState(moment.utc(0).format('YYYY-MM-DD'))
  const [isExportDisabled, setIsExportDisabled] = useState(true)
  const { reportName } = props
  let reportEndpoint = ''
  let shouldDisplay = ''
  // the previous reporting system used by Backendless would use
  // different date offsets/strategies for different reports. These
  // transform functions is used to apply those offsets/mutations
  let params = {
    email: formData?.email,
    ...(formData?.location && { location: formData.location }),
    ...(formData?.fromDate && {
      fromDate: formatDateYyyyMmDd(formData.fromDate),
    }),
    ...(formData?.toDate && {
      toDate: formatDateYyyyMmDd(formData.toDate),
    }),
  }

  switch (reportName) {
    case 'Completed Work Orders':
      reportEndpoint = `${process.env.REACT_APP_ORIGIN}/reportsV2/completed-work-orders-report`
      break
    case 'Work Orders With Tire Rack Orders':
      reportEndpoint = `${process.env.REACT_APP_ORIGIN}/reportsV2/work-orders-with-tro-orders-report`
      break
    case 'Customer Rating By Technician':
      reportEndpoint = `${process.env.REACT_APP_ORIGIN}/reportsV2/customer-rating-by-technician-report`
      break
    case 'Free Discounted and Cancelled Onsite':
      reportEndpoint = `${process.env.REACT_APP_ORIGIN}/reportsV2/free-discounted-and-onsite-cancellations-report`
      break
    case 'Accounting Report: Invoice Reconciliation':
      reportEndpoint = `${process.env.REACT_APP_ORIGIN}/reportsV2/invoices-accounting-report`
      break
    case 'Work Order / Invoices - Accounting':
      reportEndpoint = `${process.env.REACT_APP_ORIGIN}/reportsV2/work-orders-accounting-report`
      break
  }

  function handleClosed() {
    setSuccessMessage('')
    setFormData({
      fromDate: null,
      toDate: null,
      location: '',
      email: '',
    })
    props.handleClosed()
  }

  function handleDate(e: any) {
    const { name, value } = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: new Date(value),
    }))
  }

  function handleEmailChange(e: any) {
    const { name, value } = e.target
    setFormData((prevData) => {
      console.log(prevData)
      return {
        ...prevData,
        [name]: value,
      }
    })
    if (value) {
      setValidEmail(isValidEmail(value))
    } else {
      setValidEmail(null)
    }
  }

  async function handleExport() {
    try {
      if (successMessage) {
        setSuccessMessage('')
      }
      setIsLoading(true)
      const data = await fetchData<{ success: string }>(
        getReport(reportEndpoint, params),
      )
      setSuccessMessage(data.success)
    } catch (err) {
      console.error(err)
      alert(err)
    } finally {
      setIsLoading(false)
    }
  }

  // toDate input validation
  useEffect(() => {
    if (!!formData.fromDate) {
      // once a fromDate has been chosen, we can display the toDate calendar correctly
      setIsToDateDisabled(false)

      setMinToDate(moment.utc(formData.fromDate).format('YYYY-MM-DD'))

      // If the fromDate becomes greater than the toDate, we need to reset the toDate
      const fromDateLessThanToDate =
        formData.fromDate.valueOf() < (formData.toDate?.valueOf() ?? Infinity)
      if (!fromDateLessThanToDate) {
        // set the toDate state
        setFormData((prevData) => ({
          ...prevData,
          toDate: null,
        }))

        // reset the toDate
        const toDateInput = document.getElementsByName(
          'toDate',
        )[0] as HTMLInputElement
        if (toDateInput) {
          toDateInput.value = ''
        }
      }
    }
  }, [formData.fromDate])

  // deciding if export button is disabled
  useEffect(() => {
    const { fromDate, toDate, email } = formData
    setIsExportDisabled(
      !email || !validEmail || !fromDate || !toDate || isLoading,
    )
  }, [
    validEmail,
    formData.fromDate,
    formData.toDate,
    formData.email,
    isLoading,
  ])

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={handleClosed}
      >
        <Box className={styles.scheduleModal}>
          <div className={styles.reportTitle}>{reportName}</div>
          <div className={styles.inputsContainer}>
            <TextField
              type='date'
              label='From Date'
              name='fromDate'
              InputLabelProps={{ shrink: true }}
              sx={{ width: '162px', display: shouldDisplay }}
              onChange={handleDate}
            />
            <TextField
              type='date'
              label='To Date'
              name='toDate'
              disabled={isToDateDisabled}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: minToDate }}
              sx={{ width: '162px', display: shouldDisplay }}
              onChange={handleDate}
            />
            <LocationDropDown
              showInactive
              disabled={reportName === 'Work Order / Invoices - Accounting'}
              onLocationChange={(locationId: string) =>
                setFormData((prevData) => ({
                  ...prevData,
                  location: locationId,
                }))
              }
            />
            <TextField
              label='Email'
              name='email'
              type='email'
              sx={{ width: '300px' }}
              value={formData.email}
              error={validEmail === false}
              helperText={
                validEmail === false ? 'Please enter a valid email address' : ''
              }
              onChange={handleEmailChange}
            />
          </div>
          <div className={styles.successMessage}>
            {successMessage ? (
              successMessage
            ) : isLoading ? (
              <CircularProgress size={30} />
            ) : null}
          </div>
          <div>
            <PrimaryButton
              buttonName='Export'
              disabled={isExportDisabled}
              onClick={handleExport}
            />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
