export function payOrderTips(objectId: string | undefined, amount: number) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${objectId}/pay-tips?amount=${amount}`,
  }
}
export function getInvoice(orderObjectId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/invoice/order/${orderObjectId}`,
  }
}

export function getOrderByObjectIdNoAuth(objectId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/by-object-id/${objectId}/no-auth`,
  }
}

export function getTechnician(orderObjectId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/technicians/byOrderId/${orderObjectId}`,
  }
}
