import { IOrderService } from '../../../../../../../app/entities/OrderService'
import { AvailabilityCalendarSlot } from '../../../../../../../app/types/scheduling'
import { ISchedulerStateProps } from '../container-modal/initialData'

export const getTireSize = (vehicle: string) => {
  const encoded = encodeURIComponent(vehicle)
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/vehicles/tire-sizes?vehicle=${encoded}`,
  }
}

export interface UpdateOrderStatusToScheduledOptions {
  availabilityCalendarSlot?: AvailabilityCalendarSlot
  reason?: string
  username?: string
}

export const updateOrderStatusToScheduled = (
  orderId: string,
  options: UpdateOrderStatusToScheduledOptions = {},
) => {
  const { availabilityCalendarSlot, reason, username } = options

  const params = new URLSearchParams()
  if (reason) params.append('reason', reason)
  if (username) params.append('username', username)

  return {
    method: 'POST',
    url:
      `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/status/scheduled` +
      (params.toString() ? `?${params.toString()}` : ''),
    body: availabilityCalendarSlot ? availabilityCalendarSlot : {},
    returnRaw: true,
  }
}

export const createTireCombination = (body: any) => {
  return {
    method: 'POST',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/tires_combination`,
  }
}
export const updateTireCombination = (body: any) => {
  return {
    method: 'PUT',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/tires_combination`,
  }
}

export const setTireCombination = (
  body: any,
  orderId: string,
  orderVehicleId: string,
) => {
  return {
    method: 'POST',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/vehicles/${orderVehicleId}/tires`,
  }
}
export const updateVehicleMileage = (objectId: string, mileage: number) => {
  const body = {
    objectId,
    mileage,
  }
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/order_vehicles`,
    body: body,
  }
}
export const createOrderService = (body: any) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/order_service`,
    body,
  }
}
export const setOrderServices = (
  orderObjectId: string | undefined,
  orderVehicleObjectId: string,
  orderServiceId: string,
) => {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/vehicles/${orderVehicleObjectId}/order-services`,
    body: [orderServiceId],
  }
}

export const handleGenericItems = (
  orderObjectId: string,
  genericItems: IOrderService[],
) => {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/generic-items`,
    body: genericItems,
  }
}

export const getPsiTorqueSuggestions = (body: {
  year: string
  make: string
  model: string
  variant: string
  frontTireWidth: string
  frontTireAspectRatio: string
  frontTireDiameter: string
  rearTireWidth: string
  rearTireAspectRatio: string
  rearTireDiameter: string
}) => {
  const query = new URLSearchParams(body)

  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/psi-torque/suggestions?${query.toString()}`,
  }
}

export const getLocationServiceByServiceForOrder = (
  schedulerState: ISchedulerStateProps,
) => {
  const { service: serviceObjectId, orderId: orderObjectId } = schedulerState
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/location_service/by-service-for-order?orderObjectId=${orderObjectId}&serviceObjectId=${serviceObjectId}`,
  }
}
