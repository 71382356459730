// This file is nearly replicated at bi/constants/bi-reports.ts; please update both files when updating this content.
type ValueOf<T> = T[keyof T]

const BI_DATA_WAREHOUSE_INPUT_CSV_COLUMN_COUNT = {
  ACCOUNTING_TRANSACTION_ADJUSTMENT: 11,
  ATA_ACCOUNT_DEFINITION: 5,
  ATA_GL_TRANSACTION: 11,
  DTC_INVOICE: 16,
  DTC_STORE: 12,
  HUB_SPOKE_STORE_MAPPING: 5,
  LOCATION: 14,
  WORK_ORDER: 27,
}

const BI_DATA_WAREHOUSE_TABLE_NAMES = {
  ACCOUNTING_TRANSACTION_ADJUSTMENT: 'Accounting Transaction Adjustment',
  ATA_ACCOUNT_DEFINITION: 'ATA Account Definition',
  ATA_GL_TRANSACTION: 'ATA GL Transaction',
  DTC_INVOICE: 'DTC Invoice',
  DTC_STORE: 'DTC Store',
  DTMI_VAN: 'DTMI Van',
  DTMI_VAN_OPERATING_STATUS: 'DTMI Van Operating Status',
  DTMI_WORK_ORDER: 'DTMI Work Order',
  HUB_SPOKE_STORE_MAPPING: 'Hub Spoke Store Mapping',
  LOCATION: 'Location',
  TRMI_VAN: 'TRMI Van',
  TRMI_VAN_OPERATING_STATUS: 'TRMI Van Operating Status',
  TRMI_WORK_ORDER: 'TRMI Work Order',
}
export type BiEntityNames = ValueOf<typeof BI_DATA_WAREHOUSE_TABLE_NAMES>

const BI_REPORT_MODAL_DISPLAY_MODES = {
  EDIT: 'edit',
  DELETE: 'delete',
} as const
export type BiReportModalDisplayModes = ValueOf<
  typeof BI_REPORT_MODAL_DISPLAY_MODES
>

const FOLDER_NAMES = {
  UPLOAD: 'uploads',
  SAMPLE: 'samples',
  EXPORT: 'export',
}

const SAMPLE_FILE_NAMES: {
  [x: string]: string
} = {
  [BI_DATA_WAREHOUSE_TABLE_NAMES.ACCOUNTING_TRANSACTION_ADJUSTMENT]:
    'accounting-transaction-adjustment.csv',
  [BI_DATA_WAREHOUSE_TABLE_NAMES.ATA_ACCOUNT_DEFINITION]:
    'ata-account-definition.csv',
  [BI_DATA_WAREHOUSE_TABLE_NAMES.ATA_GL_TRANSACTION]: 'ata-gl-transaction.csv',
  [BI_DATA_WAREHOUSE_TABLE_NAMES.DTC_INVOICE]: 'dtc-invoice.csv',
  [BI_DATA_WAREHOUSE_TABLE_NAMES.DTC_STORE]: 'dtc-store.csv',
  [BI_DATA_WAREHOUSE_TABLE_NAMES.HUB_SPOKE_STORE_MAPPING]:
    'hub-spoke-store-mapping.csv',
  [BI_DATA_WAREHOUSE_TABLE_NAMES.LOCATION]: 'location.csv',
}

export {
  BI_DATA_WAREHOUSE_INPUT_CSV_COLUMN_COUNT,
  BI_DATA_WAREHOUSE_TABLE_NAMES,
  BI_REPORT_MODAL_DISPLAY_MODES,
  FOLDER_NAMES,
  SAMPLE_FILE_NAMES,
}
