function removeEmptyLeaves(obj: any) {
  const cleaned = Object.entries(obj).reduce((acc, [key, value]) => {
    if (value && typeof value === "object") {
      const nested = removeEmptyLeaves(value)
      if (Object.keys(nested).length) acc[key] = nested
    } else if (value) {
      acc[key] = value
    }
    return acc
  }, {} as any)
  return cleaned
}



// allows updates to an object without overwriting existing parts of the object
function softMerge(update: any, target: any) {
  // Clone the target object to avoid modifying the original object
  const result = { ...target };

  // Iterate over each key in the update object
  for (const key in update) {
    if (typeof update[key] === 'object' && update[key] !== null && !Array.isArray(update[key])) {
      // If the value in the update object is an object (excluding arrays and null), perform a recursive merge
      result[key] = softMerge(update[key], result[key] || {});
    } else {
      // Otherwise, perform a direct update on the target object
      result[key] = update[key];
    }
  }

  return result;
}

function handleColumnFilterUpdate(filtersUpdate: object, columnFilters: object, setColumnFilters: Function) {
  const mergedFilters = softMerge(filtersUpdate, columnFilters)

  const cleanedFilters = removeEmptyLeaves(mergedFilters)

  setColumnFilters(cleanedFilters)
}

export {
  softMerge,
  removeEmptyLeaves,
  handleColumnFilterUpdate,
}