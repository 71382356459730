import { Checkbox } from '@mui/material'
import styles from '../../styles.module.css'
import { useState } from 'react'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import { fetchData } from '../../../../global/utils/fetch'
import { putToggleStandbyQueueNoAuth } from '../../../api'
import { IAlertStatusProps } from '../../../../global/components/toast-alert/ToastNotification'
import { SUPPORT_PHONE_NUMBER } from '../../../../global/notifications'

interface ConfirmToggleStandbyProps {
  alertStatus: IAlertStatusProps
  handleClose: Function
  isOpen: boolean
  isStandbyQueueChecked: boolean
  orderObjectId: string | undefined
  setAlertStatus: Function
}

const ERR_TOGGLE_STANDBY = [
  `There has been an error, please try again. If problem persists, contact`,
  `customer support at ${SUPPORT_PHONE_NUMBER}`,
].join(' ')

export default function ConfirmToggleStandby(props: ConfirmToggleStandbyProps) {
  const {
    alertStatus,
    handleClose,
    isOpen,
    isStandbyQueueChecked,
    orderObjectId,
    setAlertStatus,
  } = props

  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function toggleStandbyQueue() {
    try {
      setIsLoading(true)
      await fetchData(
        putToggleStandbyQueueNoAuth(orderObjectId ?? '', isStandbyQueueChecked),
      )
      handleClose(true)
    } catch (err) {
      console.error(err)
      setAlertStatus({
        ...alertStatus,
        isOpen: true,
        message: ERR_TOGGLE_STANDBY,
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isOpen && (
        <div className={styles.inputsContainer}>
          <div className={styles.subModalHeader}>Confirm</div>
          <div className={styles.checkBoxContainer}>
            <Checkbox onChange={() => setIsChecked(!isChecked)} />
            <div>
              {isStandbyQueueChecked
                ? 'Yes, I want to be notified of earlier appointments'
                : 'I no longer want to be notified of earlier appointments'}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <SecondaryButton
              buttonName='Cancel'
              onClick={() => {
                handleClose()
              }}
            />
            <PrimaryButton
              buttonName='Confirm'
              disabled={!isChecked || isLoading}
              onClick={toggleStandbyQueue}
            />
          </div>
        </div>
      )}
    </>
  )
}
