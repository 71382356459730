import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { fetchData } from '../../../../global/utils/fetch'
import { quickReports, quickReportsWithParams } from '../../api'
import moment from 'moment'
import DownloadButton from '../buttons/download'
import {
  DOWNLOAD_REPORTS_CATEGORY,
  DOWNLOAD_REPORTS_CATEGORY_TYPE,
} from '../../../../global/constants/reports'
import {
  quickReportButtons,
  quickReportButtonsWithDates,
} from './report-buttons/quick-report-buttons'
import {
  newInvoiceReportsButtons,
  newInvoiceReportsButtonsWithDates,
} from './report-buttons/new-invoice-reports-buttons'
import { formatDateYyyyMmDd } from '../../../../global/utils/date/date-utils-yyyy-mm-dd'

export interface ReportButtonProps {
  buttonName: string
  reportName: string
  reportCall?: any
  description?: string
}

interface IDownloadReportsModalProps {
  isOpen: boolean
  handleClosed: Function
  category: DOWNLOAD_REPORTS_CATEGORY_TYPE
}

export type ReportsV2ResponseType =
  | { csv: string }
  | { availabilityReportUrl: string }

export default function DownloadReportsModal(
  props: IDownloadReportsModalProps,
) {
  const { isOpen, handleClosed, category } = props
  const [reportData, setReportData] = useState<{ [key: string]: string }>({})
  const [reportLoading, setReportLoading] = useState<string>('')
  const initialFromDate = moment.utc().toDate()
  const initialToDate = moment.utc().toDate()
  initialFromDate.setDate(initialFromDate.getDate() - 7)
  const [minToDate, setMinToDate] = useState(
    formatDateYyyyMmDd(initialFromDate),
  )
  const [dates, setDates] = useState({
    fromDate: formatDateYyyyMmDd(initialFromDate),
    toDate: formatDateYyyyMmDd(initialToDate),
  })
  const [reportButtons, setReportButtons] = useState([] as ReportButtonProps[])
  const [reportButtonsWithDates, setReportButtonsWithDates] = useState(
    [] as any[],
  )
  function handleDateChange(e: any) {
    setDates((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  function storeResult(reportName: string, result: any) {
    if ('csv' in result) {
      setReportData((prevState) => ({ ...prevState, [reportName]: result.csv }))
    } else if ('availabilityReportUrl' in result) {
      setReportData((prevState) => ({
        ...prevState,
        [reportName]: result.availabilityReportUrl,
      }))
    }
  }

  async function handleReport(reportName: string, reportCall?: Function) {
    try {
      setReportLoading(reportName)
      const call = reportCall ? reportCall() : quickReports(reportName)
      const res = await fetchData<ReportsV2ResponseType>(call)
      storeResult(reportName, res)
    } catch (err) {
      console.error(err)
    } finally {
      setReportLoading('')
    }
  }
  async function handleReportWithDates(
    reportName: string,
    reportCall?: Function,
  ) {
    try {
      const params = {
        fromDate: dates.fromDate,
        toDate: dates.toDate,
      }
      setReportLoading(reportName)
      const call = reportCall
        ? reportCall(params)
        : quickReportsWithParams(reportName, params)
      const res = await fetchData<ReportsV2ResponseType>(call)
      storeResult(reportName, res)
    } catch (err) {
      console.error(err)
    } finally {
      setReportLoading('')
    }
  }
  function handleCloseModal() {
    setReportData({})
    setReportLoading('')
    handleClosed()
  }
  function downloadButtonLogic(buttonName: string, reportName: string) {
    if (reportData[reportName] !== undefined) {
      if (reportData[reportName] === '') {
        return <div>No data available to download</div>
      } else {
        return (
          <DownloadButton
            data={reportData[reportName]}
            isUrl={buttonName === 'Availability Report'}
            reportName={reportName}
          />
        )
      }
    }
  }

  useEffect(() => {
    if (category === DOWNLOAD_REPORTS_CATEGORY.QUICK_REPORTS) {
      setReportButtons(quickReportButtons)
      setReportButtonsWithDates(quickReportButtonsWithDates)
    } else if (category === DOWNLOAD_REPORTS_CATEGORY.NEW_INVOICE_REPORTS) {
      setReportButtons(newInvoiceReportsButtons)
      setReportButtonsWithDates(newInvoiceReportsButtonsWithDates)
    }
  }, [category])

  // toDate input validation
  useEffect(() => {
    const { fromDate, toDate } = dates
    const fromMoment = moment.utc(fromDate)
    const toMoment = moment.utc(toDate)

    setMinToDate(fromDate)

    // If the fromDate becomes greater than the toDate, we need to reset the toDate
    if (fromMoment.diff(toMoment, 'seconds') > 0) {
      // set the toDate state
      setDates({
        ...dates,
        toDate: fromDate,
      })

      // set the toDate display
      const toDateInput = document.getElementsByName(
        'toDate',
      )[0] as HTMLInputElement
      if (toDateInput) {
        toDateInput.value = fromDate
      }
    }
  }, [dates.fromDate])

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleCloseModal}
      >
        <Box className={styles.downloadReportsModal}>
          <div className={styles.reportTitle}>{category}</div>
          {reportButtons.length > 0 ? (
            <div
              style={{ marginBottom: 24 }}
              className={styles.reportsLinkContainer}
            >
              {reportButtons.map(
                ({ buttonName, reportName, reportCall, description }, idx) => (
                  <div
                    key={idx}
                    className={styles.reportContainer}
                  >
                    <div className={styles.buttonContainer}>
                      <button
                        className={styles.navLink}
                        onClick={() => handleReport(reportName, reportCall)}
                      >
                        {buttonName}
                      </button>
                      <div className={styles.descriptionText}>
                        {description}
                      </div>
                    </div>
                    {reportName === reportLoading && <CircularProgress />}
                    {downloadButtonLogic(buttonName, reportName)}
                  </div>
                ),
              )}
            </div>
          ) : (
            <div />
          )}
          {reportButtonsWithDates.length > 0 ? (
            <div className={styles.reportsLinkContainer}>
              <div
                className={styles.inputsContainer}
                style={{ paddingTop: 5, paddingBottom: 10 }}
              >
                <TextField
                  type='date'
                  label='From'
                  name='fromDate'
                  value={dates.fromDate}
                  onChange={handleDateChange}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  type='date'
                  label='To'
                  name='toDate'
                  value={dates.toDate}
                  onChange={handleDateChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: minToDate }}
                />
                <div className={styles.inputsLabel}>
                  Reports below require <br /> a timeframe
                </div>
              </div>
              {reportButtonsWithDates.map(
                ({ buttonName, reportName, reportCall, description }, idx) => (
                  <div
                    key={idx}
                    className={styles.reportContainer}
                  >
                    <div className={styles.buttonContainer}>
                      <button
                        className={styles.navLink}
                        onClick={() =>
                          handleReportWithDates(reportName, reportCall)
                        }
                      >
                        {buttonName}
                      </button>
                      <div className={styles.descriptionText}>
                        {description}
                      </div>
                    </div>
                    {reportName === reportLoading && <CircularProgress />}
                    {downloadButtonLogic(buttonName, reportName)}
                  </div>
                ),
              )}
            </div>
          ) : (
            <div />
          )}
        </Box>
      </Modal>
    </div>
  )
}
