import { SSM_PARAMETER_NAMES } from '../../../global/constants/ssm'
import { formatDateYyyyMmDd } from '../../../global/utils/date/date-utils-yyyy-mm-dd'

export function getSchedulingPerformanceData(date: Date, csv?: boolean) {
  const dateParam = formatDateYyyyMmDd(date)
  let url = `${process.env.REACT_APP_ORIGIN}/reportsV2/scheduling-performance-dashboard/?date=${encodeURIComponent(dateParam)}`
  if (csv) {
    url += `&csv=${csv}`
  }
  return {
    method: 'GET',
    url,
  }
}

export function getSchedulingPerformanceThresholdValues() {
  let url = [
    process.env.REACT_APP_ORIGIN,
    `/aws-ssm-parameter?parameterName=${encodeURIComponent(SSM_PARAMETER_NAMES.SCHEDULING_PERFORMANCE_FLAG_VALUES)}`,
  ].join('')
  return {
    method: 'GET',
    url,
  }
}
