import { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
//import TiresTable from './tables/TiresTable'
import RouteBuilder from './route-builder/RouteBuilder'
import InvoiceDetails from './tables/InvoiceDetails'
import CsvUploads from './csv-uploads/CsvUploads'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import { fetchData } from '../../../../global/utils/fetch'
import { getOrderById, getOrderVehicleData } from '../../api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { IOrderVehiclePartial } from '../../../../../../app/entities/OrderVehicle'
import { UserContext } from '../../../../base/components/base-container/BaseContainer'
import hasPermission from '../../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../../global/constants/permissions'
interface ITeamToolsProps {
  isOpen: boolean
  handleClosed: Function
}
const VehicleInfo = (workOrderData: any) => {
  if (!workOrderData) {
    return <div>No Vehicle Info</div>
  }

  const { customerVehicle, tiresCombination } = workOrderData
  const rearTire = tiresCombination?.rearTire
  const frontTire = tiresCombination?.frontTire
  return (
    <div className={styles.vehicleInfo}>
      <div className={styles.vehicleRow}>
        <div className={styles.rowTitle}>Vehicle</div>
        <div>Year: {customerVehicle?.year}</div>
        <div>Make: {customerVehicle?.brand?.name}</div>
        <div>Model: {customerVehicle?.model}</div>
        <div>Variant: {customerVehicle?.variant}</div>
      </div>
      <div className={styles.vehicleRow}>
        <div className={styles.rowTitle}>Front Tires</div>
        {frontTire ? (
          <>
            <div>Width: {frontTire.width}</div>
            <div>Aspect Ratio: {frontTire.aspectRatio}</div>
            <div>Diameter: {frontTire.diameter}</div>
            <div>Construction: {frontTire.construction}</div>
            <div>OE Type: {frontTire.oetype}</div>
            <div>Season: {frontTire.season}</div>
            <div>Speed Index: {frontTire.speedIndex}</div>
          </>
        ) : (
          <>No front tires.</>
        )}
      </div>
      <div className={styles.vehicleRow}>
        <div className={styles.rowTitle}>Rear Tires</div>
        {rearTire ? (
          <>
            <div>Width: {rearTire.width}</div>
            <div>Aspect Ratio: {rearTire.aspectRatio}</div>
            <div>Diameter: {rearTire.diameter}</div>
            <div>Construction: {rearTire.construction}</div>
            <div>OE Type: {rearTire.oetype}</div>
            <div>Season: {rearTire.season}</div>
            <div>Speed Index: {rearTire.speedIndex}</div>
          </>
        ) : (
          <>No rear tires.</>
        )}
      </div>
    </div>
  )
}

export default function CsTeamToolsModal(props: ITeamToolsProps) {
  const { user } = useContext(UserContext)
  const [searchInput, setSearchInput] = useState('')
  const [workOrderData, setWorkOrderData] = useState<IOrderVehiclePartial>({})
  const [invoiceTableProps, setInvoiceTableProps] = useState('')

  const canUploadCsv = hasPermission(user).allow(
    PERMISSIONS.CS_REPORTS.CATEGORY,
    PERMISSIONS.CS_REPORTS.ENTRIES.CS_TEAM_TOOLS_CSV_UPLOADS.NAME,
  )

  function closeHandler() {
    setWorkOrderData({})
    setSearchInput('')
    setInvoiceTableProps('')
    props.handleClosed()
  }
  async function fetchOrderData() {
    //currently no way to query order by workOrderId, so this is a work around using table query for workOrders with a filter by workOrderId, then using the responses objectID to query vehicle data
    try {
      const getWorkOrderIdResponse = await fetchData<any>(
        getOrderById(searchInput),
      )
      const { items } = getWorkOrderIdResponse
      const orderData = await fetchData<IOrderVehiclePartial[]>(
        getOrderVehicleData(items[0].objectId),
      )

      setInvoiceTableProps(items[0].objectId)
      setWorkOrderData(orderData[0])
    } catch (err) {
      console.error(err)
      alert(err)
    }
  }

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={closeHandler}
      >
        <Box className={styles.csToolsModal}>
          <div className={styles.pageTitle}>CS Team Tools</div>
          <div className={styles.content}>
            <div className={styles.searchBar}>
              <TextField
                label='Work Order'
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <PrimaryButton
                buttonName='Search'
                onClick={fetchOrderData}
              />
            </div>
            {VehicleInfo(workOrderData)}
            {/* <div>Below are tire details that match the vehicle from an input work order</div> */}
            <div className={styles.tablesContainer}>
              {/* <div>
								<TiresTable
									type="front"
									workOrderData={[workOrderData]}
								/>
							</div>
							<div>
								<TiresTable
									type="rear"
									workOrderData={workOrderData}
								/>
							</div> */}
            </div>
            <RouteBuilder />
            <div>
              <div className={styles.pageTitle}>Invoice Details</div>
              <div className={styles.tableContainer}>
                <InvoiceDetails workOrderId={invoiceTableProps} />
              </div>
            </div>
            {canUploadCsv && <CsvUploads />}
          </div>
        </Box>
      </Modal>
    </div>
  )
}
