export function getAvailableTechsForAssignment(
  routeOptimizationHasRan: boolean,
  date: string,
  locationId: string,
) {
  const isoDate = new Date(date).getTime()
  if (routeOptimizationHasRan) {
    return {
      method: 'GET',
      url: `${process.env.REACT_APP_ORIGIN}/technicians/available/route/${locationId}/${isoDate}`,
    }
  } else {
    return {
      method: 'GET',
      url: `${process.env.REACT_APP_ORIGIN}/technicians/available/route-area/${locationId}/${isoDate}`,
    }
  }
}
export function getFirstAvailableApp(zipCode: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/zips/${zipCode}/available-date`,
  }
}
export function getGeocode(address: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/locations/geocode?address=${encodeURIComponent(address)}`,
  }
}
export function getTechPictures(technicianIds: string[]) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/technicians/pictures?techObjectIds=${JSON.stringify(technicianIds)}`,
  }
}
export function getTechScheduleForDay(date: string, locationId: string) {
  const dateToMilliseconds = new Date(date).getTime()
  const location = locationId === 'Default (all)' ? '' : locationId
  let url = `${process.env.REACT_APP_ORIGIN}/scheduling/schedule?date=${dateToMilliseconds}`

  if (locationId !== '') {
    url += `&locationId=${location}`
  }
  return {
    method: 'GET',
    url: url,
  }
}
export function getZipCodeAvailability(zipCode?: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/zips/${zipCode}/availability`,
  }
}
export function isLocationServicedCheck(zipCode: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/zips/${zipCode}/availability`,
  }
}
//untested as get tech doesn't work
export function resetTechToRoute(routeObjectId: string, technicianId: string) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/routes/${routeObjectId}/technician`,
    body: technicianId,
  }
}
//untested as get tech doesn't work
export function resetTechToRouteArea(
  routeObjectId: string,
  selectedTechnicianObjectId: string,
  previousTechnicianObjectId: string,
) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/route_areas/${routeObjectId}/technician`,
    body: {
      selectedTechnicianObjectId,
      previousTechnicianObjectId,
    },
  }
}
