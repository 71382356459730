import Divider from '@mui/material/Divider'
import styles from './styles.module.css'
import AvailabilityByAddress from '../availability-by-address/main/AvailabilityByAddress'
import BookAppointmentsMain from '../book-appointment/main/AppointmentBookingMain'
import CheckTrOrderStatus from '../check-status/main/CheckTrOrderStatus'
import MainPageWrapper from '../../global/components/MainPageWrapper'

export default function TrSalesMain() {
  function scrollToSection(sectionId: string) {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <MainPageWrapper
      title='TR Sales'
      stickyTitle={true}
    >
      <div className={styles.mainContentContainer}>
        <div className={styles.sectionContainer}>
          <div
            id='section1'
            onClick={() => scrollToSection('section1')}
          >
            <CheckTrOrderStatus />
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.sectionContainer}>
          <div
            id='section2'
            onClick={() => scrollToSection('section2')}
          >
            <AvailabilityByAddress />
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.sectionContainer}>
          <div
            id='section3'
            onClick={() => scrollToSection('section3')}
          >
            <BookAppointmentsMain />
          </div>
        </div>
      </div>
    </MainPageWrapper>
  )
}
