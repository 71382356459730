import { useContext, useEffect, useState } from 'react'
import { IVir } from '../../../../../../../app/entities/Vir'
import TableTopper from '../../../../../global/components/table/helpers/table-topper/TableTopper'
import TableTitle from '../../../../../global/components/table/helpers/table-title/TableTitle'
import Table from '../../../../../global/components/table/Table'
import { Column } from 'react-table'
import retrieveData from '../../../../../global/components/table/helpers/retrieveData'
import {
  convertMsToYMD,
  formatTo12HourTime,
} from '../../../../../global/utils/date/dates'
import ViewVirImages from '../modal/VIewVirImages'
import { useLocation, useNavigate } from 'react-router-dom'
import { VanContext } from '../../../../../global/context/van-context/VanContext'

interface IDvirTableComponentProps {
  frequency: string
}

function DvirTable(props: IDvirTableComponentProps) {
  const {
    vanData: { objectId: vanObjectId },
  } = useContext(VanContext)
  const { frequency } = props
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    offSet: 0,
    pageSize: 10,
    count: 0,
  })
  const [virImageModalProps, setVirImageModalProps] = useState({
    isOpen: false,
    virId: '',
  })
  const [columnFilters, setColumnFilters] = useState({})

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const virIdFromUrl = queryParams.get('virId')

    setVirImageModalProps((prevState) => ({
      isOpen: !!virIdFromUrl,
      virId: virIdFromUrl || '',
    }))
  }, [location.search])

  function onRecordClickHandler(row: any) {
    const { original } = row
    // Directly navigate to update the URL, which will in turn trigger the modal to open via useEffect
    navigate(`?virId=${original.objectId}`)
  }

  function handleCloseModal() {
    const currentPath = location.pathname
    navigate(currentPath, { replace: true }) // Navigate without the virId query parameter
  }

  // function onColumnFilterUpdate(filtersUpdate: object) {
  //   handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  // }
  const endpoint = `${process.env.REACT_APP_ORIGIN}/virs/frequency/${frequency}/van/${vanObjectId}?`

  const retrieveTableData = () => {
    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      sortBy: 'created DESC',
      columnFilters,
    })
  }

  useEffect(() => {
    retrieveTableData()
  }, [tableState.pageSize, tableState.offSet, vanObjectId])

  const columns: Column<IVir>[] = [
    {
      Header: 'Date',
      accessor: (d: any) => {
        const date = d.created
        const cleanedDate = convertMsToYMD(date)
        return cleanedDate
      },
    },
    {
      Header: 'Technician',
      accessor: 'technician',
    },
    {
      Header: 'Defects',
      accessor: (d: any) => {
        return d.defects ? 'Yes' : 'No'
      },
    },
    {
      Header: 'Start Time',
      accessor: (d: any) => {
        const dateString = d.startTime
        const cleanedTime = formatTo12HourTime(dateString)
        return cleanedTime
      },
    },
    {
      Header: 'End Time',
      accessor: (d: any) => {
        const dateString = d.endTime
        const cleanedTime = formatTo12HourTime(dateString)
        return cleanedTime
      },
    },
  ]
  return (
    <>
      {virImageModalProps.isOpen && (
        <ViewVirImages
          isOpen={virImageModalProps.isOpen}
          virId={virImageModalProps.virId}
          handleClose={handleCloseModal}
        />
      )}
      <div style={{ width: '45rem', maxHeight: '400px', overflowY: 'scroll' }}>
        <TableTopper isOpen={true}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <TableTitle>{frequency.toUpperCase()}</TableTitle>
          </div>
        </TableTopper>
        <Table
          columns={columns}
          data={tableState.data as any}
          isLoading={tableState.isLoading}
          onRowClick={onRecordClickHandler}
          isOpen={true}
          isDisplayMode={true}
          isPaginated
          pagination={{ setTableState, tableState }}
        />
      </div>
    </>
  )
}

export default DvirTable
