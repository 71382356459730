import { Auth } from 'aws-amplify'
import { routesThatShouldNotRedirectDueToMissingAuth } from '../../base/components/base-container/BaseContainer'

export const refreshToken = async () => {
  try {
    const currentSession = await Auth.currentSession()
    const idToken = currentSession.getIdToken().getJwtToken()
    return idToken
  } catch (err) {
    const location = window.location
    const shouldRedirectToLoginOnNoCurrentUser =
      !routesThatShouldNotRedirectDueToMissingAuth.some((route) =>
        route.test(location.pathname),
      )

    if (shouldRedirectToLoginOnNoCurrentUser) {
      console.error(`Error refreshing token:`, err)
      throw err
    }

    return ''
  }
}
