import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import styles from './styles.module.css'
import { FullSuggestion } from '../../../../schedule/components/schedule-modal/components/choose-service/TireSizeQuantity'

interface IPsiAndTorqueDropDownProps {
  handleDropDown: Function
  options: FullSuggestion[]
}
export default function PsiAndTorqueDropDown(
  props: IPsiAndTorqueDropDownProps,
) {
  const { handleDropDown, options } = props
  const optionsMap = options.map((option: FullSuggestion, index: number) => (
    <MenuItem
      key={index}
      value={index}
    >
      {`Front Psi: ${option.frontPsi}  Rear Psi: ${option.rearPsi}  Torque: ${option.torque}`}
    </MenuItem>
  ))

  return (
    <TextField
      defaultValue={0}
      className={styles.psiAndTorqueDropDown}
      onChange={(e) => handleDropDown(e)}
      label='Options'
      select
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        },
      }}
    >
      {optionsMap}
    </TextField>
  )
}
