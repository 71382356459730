import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import styles from './styles.module.css'

interface IPrimaryCheckboxProps {
  checked: boolean
  label?: string
  className?: string
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  name?: string
  style?: React.CSSProperties
  disabled?: boolean
}

function getCheckBoxStyles(checkboxOptions: IPrimaryCheckboxProps) {
  return {
    color: '#D70000',
    '& .MuiSvgIcon-root': { fontSize: 28 },
    '&.Mui-checked': {
      color: checkboxOptions.disabled ? '' : '#D70000',
    },
    '&:hover': { backgroundColor: 'transparent' },
  }
}

export default function PrimaryCheckbox(props: IPrimaryCheckboxProps) {
  function onLabelClick() {
    if (props.onChange) {
      props.onChange(
        { target: { checked: !props.checked } } as any,
        !props.checked,
      )
    }
  }

  return (
    <>
      <div
        className={`
					${styles.checkBoxWrapper}
					${props.className}
					${props.disabled ? styles.disabledTextColor : styles.enabledTextColor}
				`}
      >
        <React.Fragment>
          <Checkbox
            disabled={props.disabled}
            sx={getCheckBoxStyles(props)}
            name={props.name}
            checked={props.checked}
            onChange={props.onChange}
            style={props.style}
          />
        </React.Fragment>
        <div
          className={props.disabled ? '' : styles.label}
          onClick={props.disabled ? () => {} : onLabelClick}
        >
          {props.label}
        </div>
      </div>
    </>
  )
}
