import { BI_DATA_WAREHOUSE_TABLE_NAMES } from '../../global/constants/bi-reports'

const {
  ACCOUNTING_TRANSACTION_ADJUSTMENT,
  ATA_ACCOUNT_DEFINITION,
  ATA_GL_TRANSACTION,
  DTC_INVOICE,
  DTMI_VAN,
  DTMI_VAN_OPERATING_STATUS,
  DTC_STORE,
  DTMI_WORK_ORDER,
  HUB_SPOKE_STORE_MAPPING,
  LOCATION,
  TRMI_VAN,
  TRMI_VAN_OPERATING_STATUS,
  TRMI_WORK_ORDER,
} = BI_DATA_WAREHOUSE_TABLE_NAMES

export function getPresignedUploadUrl(entityName: string, fileName: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/bi-reports/presigned-upload-url/${entityName}/${fileName}`,
  }
}
export function getFileUploadCredentials(
  folderName: string,
  entityName: string,
  fileName: string,
) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/bi-reports/file-upload-credentials/${folderName}/${entityName}/${fileName}`,
  }
}
export function performLookups(tableName: string) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/bi-reports/${tableName}/perform-lookups`,
  }
}
export function processFile(key: string) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/bi-reports/process-file/${key}`,
  }
}
export function deleteEntity(entityUrlPart: string, objectId: string) {
  return {
    method: 'DELETE',
    url: `${process.env.REACT_APP_ORIGIN}/${entityUrlPart}/${objectId}`,
  }
}
export function updateEntity(entityUrlPart: string, entity: any) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/${entityUrlPart}`,
    body: entity,
  }
}
export function insertEntity(entityUrlPart: string, entity: any) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/${entityUrlPart}`,
    body: entity,
  }
}
export function downloadSampleFile(entityName: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/bi-reports/download-sample-file/${entityName}`,
  }
}
export function exportCsvToS3(entityName: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/bi-reports/export-bi-csvs-to-s3/${entityName}`,
  }
}
export function refreshTableauData(entityName: string) {
  let entityParameter: string
  if (entityName === ACCOUNTING_TRANSACTION_ADJUSTMENT) {
    entityParameter = 'accountingTransactionAdjustment'
  } else if (entityName === ATA_ACCOUNT_DEFINITION) {
    entityParameter = 'ataAccountDefinition'
  } else if (entityName === ATA_GL_TRANSACTION) {
    entityParameter = 'ataGlTransaction'
  } else if (entityName === DTC_INVOICE) {
    entityParameter = 'dtcInvoice'
  } else if (entityName === DTMI_VAN) {
    entityParameter = 'dtmiVan'
  } else if (entityName === DTMI_VAN_OPERATING_STATUS) {
    entityParameter = 'dtmiVanOperatingStatus'
  } else if (entityName === DTC_STORE) {
    entityParameter = 'dtcStore'
  } else if (entityName === DTMI_WORK_ORDER) {
    entityParameter = 'dtmiWorkOrder'
  } else if (entityName === HUB_SPOKE_STORE_MAPPING) {
    entityParameter = 'hubSpokeStoreMapping'
  } else if (entityName === LOCATION) {
    entityParameter = 'location'
  } else if (entityName === TRMI_VAN) {
    entityParameter = 'trmiVan'
  } else if (entityName === TRMI_VAN_OPERATING_STATUS) {
    entityParameter = 'trmiVanOperatingStatus'
  } else if (entityName === TRMI_WORK_ORDER) {
    entityParameter = 'trmiWorkOrder'
  } else {
    throw new Error('Invalid entity name')
  }

  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/bi-reports/export-bi-csvs-to-drive`,
    body: { [entityParameter]: true },
  }
}
