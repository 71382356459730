import React, { MouseEventHandler } from 'react'
import styles from '../styles.module.css'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
interface IVanModalHeader {
  handleClosed: Function
  handleSaveClicked: (e: any) => Promise<void>
  isSaveDisabled: boolean
}
function EditVanModalHeader(props: IVanModalHeader) {
  return (
    <div className={styles.headerContainer}>
      <div className={`${styles.editVanModalPageTitle} font--bold`}>
        Van Details
      </div>
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={props.handleClosed as MouseEventHandler}
        />
        <PrimaryButton
          buttonName='Save Changes'
          onClick={props.handleSaveClicked}
          disabled={props.isSaveDisabled}
        />
      </div>
    </div>
  )
}

export default EditVanModalHeader
