import React, { MouseEventHandler } from 'react'
import styles from './styles.module.css'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import useFetch from '../../../../../global/hooks/useFetchHook'
import { getVirImages } from './api'
import SecondaryButton from '../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { sortVirImages } from './utils'
interface IProps {
  handleClose: MouseEventHandler
  isOpen: boolean
  virId: string
}
export interface virImage {
  url: string
  type: string
}
export default function ViewVirImages(props: IProps) {
  const { virId, handleClose, isOpen } = props

  const { data: virImages } = useFetch<virImage[]>(getVirImages(virId))

  const renderImages = () => {
    if (!virImages) return <CircularProgress />

    const sortedImages = sortVirImages(virImages)

    return sortedImages.map((image: any, index: number) => (
      <div
        key={index}
        className={styles.imageContainer}>
        <p className={styles.imageLabel}>{image.type}</p>
        <img
          src={image.url}
          alt={image.type}
          className={styles.virImage}
        />
      </div>
    ))
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}>
      <Box className={styles.virImageModal}>
        <div className={`${styles.pageTitle} font--bold`}>Vir Images</div>
        <div className={styles.virImagesMainContainer}>
          <div className={styles.imagesFlexContainer}>{renderImages()}</div>
          <div>
            <SecondaryButton
              buttonName="Close"
              onClick={handleClose}
            />
          </div>
        </div>
      </Box>
    </Modal>
  )
}
