export function getOrderDataNoAuth(objectId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/by-object-id/${objectId}/no-auth`,
  }
}
export function payOrderInvoice(objectId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${objectId}/pay-invoice`,
  }
}
