import { MomentInput } from 'moment'
import offsetDate from './offset-date-to-utc'

function transformDate(date: MomentInput): Date | null {
  if (!Boolean(date)) return null

  return offsetDate(date).toDate()
}

export default transformDate
