import { VIR_DAILY_PHOTOS_ARRAY, VIR_DEFECTS_PHOTOS_ARRAY, VIR_WEEKLY_PHOTOS_ARRAY } from '../../../../../global/constants/virs'
import { virImage } from './VIewVirImages'

const SORT_ORDER = [...VIR_DEFECTS_PHOTOS_ARRAY, ...VIR_WEEKLY_PHOTOS_ARRAY, ...VIR_DAILY_PHOTOS_ARRAY]

export const sortVirImages = (images: virImage[]): virImage[] => {
  return images.sort((a, b) => {
    const indexA = SORT_ORDER.indexOf(a.type)
    const indexB = SORT_ORDER.indexOf(b.type)

    if (indexA === -1) return 1 // Move a to the end if not found
    if (indexB === -1) return -1 // Move b to the end if not found

    //thank you gpt

    return indexA - indexB
  })
}
