import { MenuItem } from '@mui/material'

function generateMenuItems(items: string[]): React.ReactNode[] {
  return items.map((item) => (
    <MenuItem
      key={item}
      value={item}
    >
      {item}
    </MenuItem>
  ))
}

export default generateMenuItems
