import React from "react"
import MainPageWrapper from "../../../global/components/MainPageWrapper"
import HolidaysTable from "../table/HolidaysTable"
import NewHoliday from "./new-holiday/NewHoliday"
import HolidayModal from "../holiday-modal/container/HolidayModal"

function Holidays(props: any) {
	const { refreshCounter, setRefreshCounter } = props.refresh
	const [open, setOpen] = React.useState({
		isOpen: false,
		holidayData: {},
	})

	const handleClosed = () => {
		setOpen({ isOpen: false, holidayData: {} })
	}
	return (
		<>
			<HolidayModal
				isOpen={open.isOpen}
				handleClosed={handleClosed}
				holidayRowData={open.holidayData}
				refresh={setRefreshCounter}
			/>
			<HolidaysTable
				setOpen={setOpen}
				refresh={refreshCounter}
			/>
		</>
	)
}

function HolidaysMain() {
	const [refreshCounter, setRefreshCounter] = React.useState<number>(0)
	return (
		<MainPageWrapper titleFields={<NewHoliday refresh={{ refreshCounter, setRefreshCounter }} />}>
			<Holidays refresh={{ refreshCounter, setRefreshCounter }} />
		</MainPageWrapper>
	)
}

export default HolidaysMain
