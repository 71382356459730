import React, { ReactNode, useEffect } from 'react'
import styles from '../styles.module.css'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import { IVehicleInformationSelectProps } from '../VehicleInformationForm'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import { getBrandYears } from '../../api'
import { mode } from 'crypto-js'

const selectProps = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 500,
        width: 250,
      },
    },
  },
}

export default function YearInformationSelect(
  props: IVehicleInformationSelectProps,
) {
  const { formData } = props

  let menuItems = [] as ReactNode

  let { data, isLoading, error } = useFetch<string[]>(
    getBrandYears(formData.make),
  )

  if (data) {
    menuItems = data.map((item: string) => {
      return (
        <MenuItem
          key={item}
          value={item}
        >
          {item}
        </MenuItem>
      )
    })
  } else if (error) {
    menuItems = [<MenuItem value={'2010'}>Error - No years available</MenuItem>]
  }

  if (isLoading) {
    return <CircularProgress size={20} />
  }

  return (
    <TextField
      className={styles.textfieldStyles}
      label={props.label}
      value={formData.year}
      select
      SelectProps={selectProps}
      onChange={(e) =>
        props.onChange({
          ...props.formData,
          year: e.target.value,
          model: '',
          variant: '',
        })
      }
    >
      {menuItems}
    </TextField>
  )
}
