import { useState, useContext } from 'react'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import WODetailsSubModal from '../../sub-modals/WODetailsSubModal'
import ScheduleContainerModal from '../../scheduling-modal/container-modal/WorkOrderScheduleContainerModal'
import { WoDetailContext } from '../../../../context/context'
import { MODES, PAGES } from '../../../../../../global/constants/scheduler'
import AddRescheduledReason from '../../sub-modals/modal-content/rescheduled/AddRescheduledReason'
import { useNavigationEffect } from './hooks/useNavigationEffect'
import {
  CANCELLED_STATUSES,
  STATUSES as ORDER_STATUSES,
} from '../../../../../../global/constants/order-status'
import {
  STATUSES as INVOICE_STATUSES,
  TYPES,
} from '../../../../../../global/constants/invoice'
import { ROUTING_ALGORITHMS } from '../../../../../../global/constants/scheduling'
import { fetchData } from '../../../../../../global/utils/fetch'
import { updateOrderStatusToPendingRescheduling } from '../../../../api'
import { SUB_MODAL_NAMES } from '../../sub-modals/constants'
import isTransactionSettled from '../../../../../../global/utils/invoice/status/is-transaction-settled'
import isTransactionProcessing from '../../../../../../global/utils/invoice/status/is-transaction-processing'

export interface ITrOrderNumbersProps {
  isReadOnly: boolean
  trOrderNum?: string
  addTrOrderNum?: string
}

export interface ITrOrderNumbersData {
  trOrderNum?: string
  addTrOrderNum?: string
}

export default function OrderActions({ isReadOnly }: ITrOrderNumbersProps) {
  const { data: order } = useContext(WoDetailContext)
  const [trOrderNumInputs, setTrOrderNumInputs] = useState<ITrOrderNumbersData>(
    {
      trOrderNum: order.tireRackOrders?.length
        ? order.tireRackOrders[0].orderNumber ?? ''
        : '',
      addTrOrderNum: order.additionalTireRackOrderNumber ?? '',
    },
  )
  //scheduler modal
  const [scheduleModalOpen, setScheduleModalOpen] = useState<boolean>(false)
  const [disableSchedulerNavigation, setDisableSchedulerNavigation] =
    useState<boolean>(false)
  const [addRescheduleReasonModalOpen, setAddRescheduleReasonModalOpen] =
    useState<boolean>(false)
  const [schedulerModalName, setSchedulerModalName] = useState<string>(
    PAGES.CUSTOMER_INFORMATION,
  )
  const [rescheduleReason, setRescheduleReason] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  //mode: controls the flow depending on the button clicked
  //e.g. if "finish" is clicked, the modal will navigate through the whole scheduling flow instead of closing after saving the customer info
  const [mode, setMode] = useState<MODES>(MODES.EDIT)

  function handleRescheduleClick() {
    if (order.status !== ORDER_STATUSES.reschedule) {
      handleOpenAddRescheduleReason()
    } else {
      // this is where the status is already "pending_rescheduling" so we can skip the reschedule reason
      setSchedulerModalName(PAGES.APPOINTMENT_SLOT)
      setMode(MODES.EDIT)
      setDisableSchedulerNavigation(true)
      setScheduleModalOpen(true)
    }
  }

  function handleOpenAddRescheduleReason() {
    setSchedulerModalName('Reschedule Reason')
    setAddRescheduleReasonModalOpen(true)
  }
  function handleAddRescheduleReasonCanceled() {
    setAddRescheduleReasonModalOpen(false)
  }
  async function handleAddRescheduleReasonConfirmed(reason: string) {
    setIsLoading(true)
    setRescheduleReason(reason)
    if (order.location?.routingAlgorithm === ROUTING_ALGORITHMS.GREEDY) {
      // we must first update the order status to pending rescheduling
      // in order for the calendar to show the correct availability
      await fetchData<void>(
        updateOrderStatusToPendingRescheduling(order.objectId, reason),
      )
    } else {
      throw new Error(
        `Routing algorithm ${order.location?.routingAlgorithm} is not supported`,
      )
    }
    setAddRescheduleReasonModalOpen(false)
    setSchedulerModalName(PAGES.APPOINTMENT_SLOT)
    setDisableSchedulerNavigation(true)
    setScheduleModalOpen(true)
    setIsLoading(false)
  }
  function handleClosedScheduler() {
    setScheduleModalOpen(false)
    setSchedulerModalName('')
  }

  function isOrderCompleted(): boolean {
    return order.status === ORDER_STATUSES.completed
  }
  function shouldDisableEditOrder(): boolean {
    if (isReadOnly) {
      return true
    }

    let shouldDisableEditOrder = false
    if (order.invoices.length) {
      const hasSettledInvoice = Boolean(
        order.invoices.find(
          (invoice) =>
            !invoice.tip &&
            invoice.type === TYPES.COMPLETED &&
            isTransactionSettled(invoice),
        ),
      )
      const hasProcessingInvoice = Boolean(
        order.invoices.find(
          (invoice) =>
            !invoice.tip &&
            invoice.type === TYPES.COMPLETED &&
            isTransactionProcessing(invoice),
        ),
      )
      const hasFreeInvoice = Boolean(
        order.invoices.find(
          (invoice) => !invoice.tip && invoice.status === INVOICE_STATUSES.FREE,
        ),
      )
      shouldDisableEditOrder =
        hasProcessingInvoice ||
        hasSettledInvoice ||
        (hasFreeInvoice && isOrderCompleted())
    }

    return shouldDisableEditOrder
  }

  //WODetailsSubModal
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  function handleOpen(e: any) {
    setSchedulerModalName(e.target.innerText)
    setModalOpen(true)
  }
  function handleClosed() {
    setModalOpen(false)
    setSchedulerModalName('')
  }
  function finishTrOrderHandler() {
    setMode(MODES.FINISH)
    setSchedulerModalName(PAGES.CUSTOMER_INFORMATION)
    setScheduleModalOpen(true)
  }

  /* Finish button should be enabled for
   *  - 'quote's w/ TR order
   *  - any clone in 'quote'
   */

  const shouldEnableFinishOrderButton =
    !isReadOnly &&
    order.status === 'quote' &&
    (order.tireRackOrder || order.createdFrom)
  const shouldDisableAddTrOrderButton = !trOrderNumInputs.addTrOrderNum
  const shouldDisableReschedule =
    isReadOnly ||
    [...CANCELLED_STATUSES, ORDER_STATUSES.completed].includes(
      order.status ?? '',
    )

  //custom hook to open finish flow when redirected here from cloning order
  useNavigationEffect(order, finishTrOrderHandler)

  const shouldDisableCancelOrder =
    isReadOnly ||
    [...CANCELLED_STATUSES, ORDER_STATUSES.completed].includes(
      order.status ?? '',
    )

  return (
    <div className={styles.orderActionsContainer}>
      <TextField
        label='TR Order Number'
        value={trOrderNumInputs.trOrderNum}
        onChange={(e) =>
          setTrOrderNumInputs((prevState) => ({
            ...prevState,
            trOrderNum: e.target.value,
          }))
        }
        InputLabelProps={{ shrink: true }}
        sx={{ width: '200px', marginRight: '8px' }}
        disabled={isReadOnly}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <button
                className={styles.inputButton}
                onClick={handleOpen}
                disabled={isReadOnly}
              >
                {SUB_MODAL_NAMES.ASSIGN}
              </button>
              <div className={styles.line}></div>
              <button
                className={styles.inputButton}
                disabled={!shouldEnableFinishOrderButton}
                onClick={finishTrOrderHandler}
              >
                Finish
              </button>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Add'l TRO Number"
        value={trOrderNumInputs.addTrOrderNum}
        onChange={(e) =>
          setTrOrderNumInputs((prevState) => ({
            ...prevState,
            addTrOrderNum: e.target.value,
          }))
        }
        InputLabelProps={{ shrink: true }}
        sx={{ width: '120px' }}
        disabled={isReadOnly}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <button
                className={styles.inputButton}
                onClick={handleOpen}
                disabled={isReadOnly || shouldDisableAddTrOrderButton}
              >
                {SUB_MODAL_NAMES.SAVE}
              </button>
            </InputAdornment>
          ),
        }}
      />
      <div className={styles.additionalActions}>
        <WODetailsSubModal
          isOpen={modalOpen}
          handleClosed={handleClosed}
          actionName={schedulerModalName}
          trNumbers={trOrderNumInputs}
        />
        <AddRescheduledReason
          isOpen={addRescheduleReasonModalOpen}
          handleCancel={handleAddRescheduleReasonCanceled}
          handleConfirm={handleAddRescheduleReasonConfirmed}
          isLoading={isLoading}
        />
        <ScheduleContainerModal
          isOpen={scheduleModalOpen}
          handleClosed={handleClosedScheduler}
          route={schedulerModalName}
          mode={mode}
          setModalName={setSchedulerModalName}
          disableSchedulerNavigation={disableSchedulerNavigation}
          rescheduleReason={rescheduleReason}
          isOrderCompleted={isOrderCompleted()}
        />
        <button
          className={styles.button}
          disabled={shouldDisableEditOrder()}
          onClick={() => {
            setSchedulerModalName(
              isOrderCompleted()
                ? PAGES.PAYMENT_INFORMATION
                : PAGES.CUSTOMER_INFORMATION,
            )
            setMode(MODES.EDIT)
            setDisableSchedulerNavigation(false)
            setScheduleModalOpen(true)
          }}
        >
          Edit Order
        </button>
        <button
          className={styles.button}
          onClick={(e) => handleOpen(e)}
          disabled={isReadOnly}
        >
          {SUB_MODAL_NAMES.SEND_CUSTOMER_CC_UPDATE}
        </button>
        <button
          className={styles.button}
          onClick={() => {
            handleRescheduleClick()
          }}
          disabled={shouldDisableReschedule}
        >
          Reschedule
        </button>
        <button
          className={styles.button}
          onClick={(e) => handleOpen(e)}
          disabled={shouldDisableCancelOrder}
        >
          {SUB_MODAL_NAMES.CANCEL_ORDER}
        </button>
      </div>
    </div>
  )
}
