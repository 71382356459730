import { useState } from "react"
import tableStyle from '../styles.module.css'

interface BasicFilterProps {
    onEnter?: Function
    onChange?: Function
}

function BasicFilter({ onEnter, onChange }: BasicFilterProps) {


    const [message, setMessage] = useState('')

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (onEnter && event.key === "Enter") {
            onEnter(message)
        }
    }

    function handleChange(event: any) {
        setMessage(event.target.value)
        if (onChange) {
            onChange(event.target.value)
        }
    }

    return (
        <span className={tableStyle.filterSymbol}>
            <input
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={`Filter`}
                className="-filters"
            />
        </span>
    )
}

export default BasicFilter