import { getDayName, getWeekNumber } from '../../../../utils/date/dates'
import { IGetTechScheduleRouteArea } from '../../../../../schedule/components/technician-table/table-container/TechTableContainer'
import { IDaySetting } from '../../../../../../../app/entities/DaySetting'

export const determineWorkingStatus = (
  techInfo: IGetTechScheduleRouteArea,
  selectedScheduleDate: number,
) => {
  const { technician } = techInfo
  const { weekCalendars = [], daySettings = [] } = technician as any

  const date = new Date(selectedScheduleDate)

  if (isNaN(date.getTime())) {
    return { isWorking: false, isDaySetting: false }
  }
  const [year, month, day] = date.toISOString().split('T')[0].split('-')

  const formattedDate = `${month}/${day}/${year.slice(-2)}`

  const daySetting = daySettings.find(
    (daySetting: IDaySetting) => daySetting.dayDate === formattedDate,
  )

  if (daySetting) {
    return { isWorking: daySetting.workDay, isDaySetting: true }
  }

  const parity = getWeekNumber(selectedScheduleDate) % 2 === 0 ? 'even' : 'odd'

  const calendar = weekCalendars.find(
    (calendar: any) => calendar.weekName === parity,
  )

  if (!calendar) {
    return { isWorking: false, isDaySetting: false }
  }

  const dayOfWeek = getDayName(selectedScheduleDate).toLocaleLowerCase()

  return { isWorking: calendar[dayOfWeek], isDaySetting: false }
}

export const displayTechWorkHours = ({
  location,
  technician,
}: IGetTechScheduleRouteArea) => {
  let displayTime
  const { workDayFrom, workDayTo } = technician
  const { workDayFrom: lWorkDayFrom, workDayTo: lWorkDayTo } = location
  //defaults to location workday hours if tech has no work hours set

  if (workDayFrom && workDayTo) {
    displayTime = `${workDayFrom} - ${workDayTo}`
  } else if (workDayFrom) {
    displayTime = `${workDayFrom} - N/A`
  } else if (workDayTo) {
    displayTime = `N/A - ${workDayTo}`
  } else {
    displayTime = `${lWorkDayFrom} - ${lWorkDayTo}`
  }
  return displayTime
}
