import TextField from '@mui/material/TextField'
import styles from './styles.module.css'
import { ChangeEvent, MouseEventHandler, useEffect, useState } from 'react'
import { ISchedulerStateProps } from '../../container-modal/initialData'
import { SUGGESTIONS_ALERT_MESSAGES } from '../TireSizeQuantity'

export interface IVehicleDetailsProps {
  schedulerState: ISchedulerStateProps
  setSchedulerState: Function
  setPsiTorqueModalOpen: MouseEventHandler
  alertProps: any
}

function VehicleDetails(props: IVehicleDetailsProps) {
  const {
    schedulerState,
    setSchedulerState,
    setPsiTorqueModalOpen,
    alertProps,
  } = props
  const {
    tires: { frontTire, rearTire },
    customerInformation,
  } = schedulerState
  const [buttonColor, setButtonColor] = useState(alertProps.color)

  function handlePsiChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    const tireKey = name === 'frontPsi' ? 'frontTire' : 'rearTire'

    setSchedulerState((prevState: ISchedulerStateProps) => {
      const newValue = value === '' ? null : Number(value)
      return {
        ...prevState,
        tires: {
          ...prevState.tires,
          [tireKey]: {
            ...prevState.tires[tireKey],
            psi: newValue,
          },
        },
      }
    })
  }

  const [isHovered, setIsHovered] = useState(false)
  function handleMouseEnter() {
    setIsHovered(true)
  }

  function handleMouseLeave() {
    setIsHovered(false)
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value, type } = event.target
    setSchedulerState((prevSchedulerState: ISchedulerStateProps) => {
      let newValue

      if (type === 'number' && value === '') {
        //inputs that are type number which === "" default to 0, so setting to null
        newValue = null
      } else if (name === 'mileage' || name === 'torque') {
        newValue = Number(value)
      } else {
        newValue = value
      }

      if (name === 'mileage') {
        return {
          ...prevSchedulerState,
          customerInformation: {
            ...prevSchedulerState.customerInformation,
            [name]: newValue,
          },
        }
      }

      return {
        ...prevSchedulerState,
        tires: {
          ...prevSchedulerState.tires,
          frontTire: {
            ...prevSchedulerState.tires.frontTire,
            [name]: newValue,
          },
          rearTire: {
            ...prevSchedulerState.tires.rearTire,
            [name]: newValue,
          },
        },
      }
    })
  }

  useEffect(() => {
    setButtonColor(
      isHovered ? alertProps.hoverColor : alertProps.backgroundColor,
    )
  }, [isHovered, alertProps.hoverColor, alertProps.backgroundColor])

  return (
    <div className={styles.vehicleDetailsContainer}>
      <div className={`${styles.detailsTitle} font--bold`}>Details</div>
      <div className={styles.inputsContainer}>
        <button
          onClick={setPsiTorqueModalOpen}
          className={styles.psiTorqueButton}
          disabled={
            alertProps.message === SUGGESTIONS_ALERT_MESSAGES.default ||
            alertProps.message ===
              SUGGESTIONS_ALERT_MESSAGES.enterInformation ||
            alertProps.message === SUGGESTIONS_ALERT_MESSAGES.noMatch
          }
          style={{ backgroundColor: buttonColor }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span className={styles.psiTorqueButtonText}>
            {alertProps.message}
          </span>
        </button>
        <div className={styles.vehicleDetailsButtonsContainer}>
          <div className={styles.psiTorqueContainer}>
            <TextField
              label='Front Psi'
              name='frontPsi'
              type='number'
              onChange={handlePsiChange}
              value={frontTire.psi ?? ''}
              InputLabelProps={{ shrink: frontTire.psi ? true : false }} // hacky way to shrink label when value is auto-filled
            />
            <TextField
              label='Rear Psi'
              name='rearPsi'
              type='number'
              onChange={handlePsiChange}
              value={rearTire.psi ?? ''}
              InputLabelProps={{ shrink: rearTire.psi ? true : false }} // hacky way to shrink label when value is auto-filled
            />
            <TextField
              label='Torque'
              name='torque'
              type='number'
              value={frontTire.torque ?? ''}
              onChange={handleChange}
              InputLabelProps={{ shrink: frontTire.torque ? true : false }} // hacky way to shrink label when value is auto-filled
            />
          </div>
          <TextField
            label='Tire Make'
            name='manufacturer'
            value={frontTire.manufacturer || rearTire.manufacturer || ''}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            label='Tire Model'
            name='model'
            value={frontTire.model || rearTire.model || ''}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            label='Vehicle Mileage'
            name='mileage'
            value={customerInformation.mileage || ''}
            type='number'
            fullWidth
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  )
}

export default VehicleDetails
