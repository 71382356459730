import { IHubSpokeStoreMapping } from '../../../../../../../../../bi/entities/HubSpokeStoreMapping'
import BasicFilter from '../../../../../../../global/components/table/helpers/BasicFilter'
import { BiReportModalDisplayModes } from '../../../../../../../global/constants/bi-reports'

function generateHubSpokeStoreMappingColumns(
  onColumnFilterUpdate: any,
  retrieveTableData: Function,
  generateTextField: Function,
  mode: BiReportModalDisplayModes,
  selectedRowIdx: number,
  handleChange: Function,
) {
  return [
    {
      Header: 'Hub Store Id',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ hubStoreId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: any, rowIndex: number) => {
        const { hubStoreId } = data
        return generateTextField(
          'hubStoreId',
          hubStoreId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Spoke Store Ids',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ spokeStoreId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: any, rowIndex: number) => {
        const { spokeStoreId } = data
        return generateTextField(
          'spokeStoreId',
          spokeStoreId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Spoke Sap Id',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ spokeSapId: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: any, rowIndex: number) => {
        const { spokeSapId } = data
        return generateTextField(
          'spokeSapId',
          spokeSapId?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Start Date',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ startDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IHubSpokeStoreMapping, rowIndex: number) => {
        const { startDate } = data
        return generateTextField(
          'startDate',
          startDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'End Date',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ endDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: IHubSpokeStoreMapping, rowIndex: number) => {
        const { endDate } = data
        return generateTextField(
          'endDate',
          endDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
  ]
}

export default generateHubSpokeStoreMappingColumns
