import { ITireRackOrder } from '../../../../../app/entities/TireRackOrder'
import { CARRIERS_WITH_TRACKING, CARRIERS_WITH_TRACKING_ARRAY, TRACKING_LINK_BASE_URLS } from '../../constants/tire-rack-order'

export type TrackingInfo = {
  trackingNumbers: string[],
  trackingLinks: string[]
  /*
   * trackingLinks will have one link if the carrier allows multiple tracking numbers to be
   * tracked in one URL, or multiple links if the carrier requires a separate URL for each
   * tracking number
   */
}

export default function generateTrackingInfo(tireRackOrder?: ITireRackOrder): TrackingInfo {
  let trackingInfo: TrackingInfo = {
    trackingNumbers: [],
    trackingLinks: []
  }

  const shipping = tireRackOrder ? tireRackOrder.shipping : undefined
  if (!shipping || !shipping.method || !shipping.tracking_numbers || !shipping.tracking_numbers.length) {
    return trackingInfo
  }

  const {
    method,
    tracking_numbers: trackingNumbers
  }: {
    method: string;
    tracking_numbers: string[]
  } = shipping

  const cleanedMethod = method.toLowerCase() as string

  // carrier is the company name, while cleanedMethod is the company name + shipping level info
  // so we use .includes() instead of ===
  const carrier = CARRIERS_WITH_TRACKING_ARRAY.find(carrier => cleanedMethod.includes(carrier))
  const isCarrierWeHaveTrackingNumbersFor = Boolean(carrier)
  if (!isCarrierWeHaveTrackingNumbersFor) {
    return trackingInfo
  }

  if (carrier === CARRIERS_WITH_TRACKING.FEDEX) {
    const baseUrl = TRACKING_LINK_BASE_URLS.FEDEX
    const trackingNumbersString = trackingNumbers.join(',')

    trackingInfo = {
      trackingNumbers,
      trackingLinks: [`${baseUrl}${trackingNumbersString}`]
    }
  } else if (carrier === CARRIERS_WITH_TRACKING.UPS) {
    const baseUrl = TRACKING_LINK_BASE_URLS.UPS
    const trackingNumbersString = trackingNumbers.join('%20')

    trackingInfo = {
      trackingNumbers,
      trackingLinks: [`${baseUrl}${trackingNumbersString}`]
    }
  } else if (carrier === CARRIERS_WITH_TRACKING.ONTRAC) {
    const baseUrl = TRACKING_LINK_BASE_URLS.ONTRAC

    trackingInfo.trackingNumbers = trackingNumbers
    trackingNumbers.forEach((trackingNumber) => {
      trackingInfo.trackingLinks.push(`${baseUrl}${trackingNumber}`)
    })
  }
  return trackingInfo
}
