import { useState, useEffect, useMemo } from 'react'
import styles from './styles.module.css'
import { fetchData } from '../../../global/utils/fetch'
import {
  getSchedulingPerformanceData,
  getSchedulingPerformanceThresholdValues,
} from './api'
import { COLUMN_DEFINITIONS } from './helpers/column-definitions'
import { getFixForLocation } from './helpers/fix-logic'

export interface SchedulingPerformanceLocationData {
  locationName: string
  integratedSchedulerRate: string
  driveTimeZeroes: number
  scheduleFullZeroes: number
  averageScheduleLeadTime: number
  sevenDayPercentBooked: string
}

interface SchedulingPerformanceData {
  schedulingPerformanceLocationData: SchedulingPerformanceLocationData[]
  totalData: (number | string)[]
}

async function getThresholdValues(setThresholdValues: Function) {
  const thresholdValuesString = await fetchData<string>(
    getSchedulingPerformanceThresholdValues(),
  )
  const thresholdValuesJson: {
    integratedSchedulerRateThreshold: number
    averageScheduleLeadTimeThreshold: number
  } = JSON.parse(thresholdValuesString)
  setThresholdValues(thresholdValuesJson)
}

function SchedulingPerformance({ date }: { date: Date }) {
  const [isLoading, setIsLoading] = useState(true)
  const [tableState, setTableState] = useState<SchedulingPerformanceData>({
    schedulingPerformanceLocationData: [],
    totalData: [],
  })
  const [thresholdValues, setThresholdValues] = useState({
    integratedSchedulerRateThreshold: 0.5,
    averageScheduleLeadTimeThreshold: 7,
  })

  useEffect(() => {
    getThresholdValues(setThresholdValues)
  }, [])

  async function fetchDashboardData() {
    setIsLoading(true)
    const data = await fetchData<SchedulingPerformanceData>(
      getSchedulingPerformanceData(date),
    )
    console.log(data)

    setTableState(data)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchDashboardData()
  }, [date])

  const renderTableRows = useMemo(() => {
    const data = tableState.schedulingPerformanceLocationData
    const rows = data.map((location: SchedulingPerformanceLocationData) => {
      const fixAndTooltip = getFixForLocation(location, thresholdValues)
      const isFixRequired = !!fixAndTooltip
      let fix = ''
      let tooltip = ''

      if (isFixRequired) {
        ;[fix, tooltip] = fixAndTooltip
      }

      return (
        <>
          <tr className={styles.row}>
            <td className={`${styles.highlightedCell} ${styles.stickyRowOne}`}>
              {location.locationName}
            </td>
            <td>{location.integratedSchedulerRate}</td>
            <td>{location.driveTimeZeroes}</td>
            <td>{location.scheduleFullZeroes}</td>
            <td>{location.averageScheduleLeadTime}</td>
            <td>{location.sevenDayPercentBooked}</td>
            {isFixRequired ? (
              <td
                className={styles.highlightedCellWithTooltip}
                data-tooltip={tooltip}
              >
                {fix}
              </td>
            ) : (
              <td></td>
            )}
          </tr>
        </>
      )
    })

    rows.push(
      <>
        <tr className={styles.row}>
          <td className={`${styles.highlightedCell} ${styles.stickyRowOne}`}>
            TRMI Total
          </td>
          {tableState?.totalData.map((percent) => <td>{percent}</td>)}
          <td></td>
        </tr>
      </>,
    )

    return rows
  }, [tableState])

  if (isLoading) {
    return <div className={styles.loading}>Loading...</div>
  }

  return (
    <div className={styles.tableWrapperContainer}>
      <table className={styles.futurePercentBookedTable}>
        <thead>
          <tr>
            <th className={styles.headerCell}>Location</th>
            <th
              className={styles.headerCellWithToolTip}
              data-tooltip={
                COLUMN_DEFINITIONS.SCHEDULING_PERFORMANCE
                  .integrated_scheduler_rate
              }
            >
              IS Rate
            </th>
            <th
              className={styles.headerCellWithToolTip}
              data-tooltip={
                COLUMN_DEFINITIONS.SCHEDULING_PERFORMANCE.drivetime_zeroes
              }
            >
              Drivetime 0s
            </th>
            <th
              className={styles.headerCellWithToolTip}
              data-tooltip={
                COLUMN_DEFINITIONS.SCHEDULING_PERFORMANCE.schedule_full_zeroes
              }
            >
              Schedule Full 0s
            </th>
            <th
              className={styles.headerCellWithToolTip}
              data-tooltip={
                COLUMN_DEFINITIONS.SCHEDULING_PERFORMANCE
                  .average_schedule_lead_time
              }
            >
              Average SLT
            </th>
            <th
              className={styles.headerCellWithToolTip}
              data-tooltip={
                COLUMN_DEFINITIONS.SCHEDULING_PERFORMANCE
                  .seven_day_percent_booked
              }
            >
              7day%Booked
            </th>
            <th className={styles.headerCell}>Fix</th>
          </tr>
        </thead>
        <tbody>{renderTableRows}</tbody>
      </table>
    </div>
  )
}

export default SchedulingPerformance
