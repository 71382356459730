import { IOrderService } from '../../../../app/entities/OrderService'
import {
  CANCELLATION_REASONS,
  CANCELLATION_DB_VALUES,
} from '../../global/constants/orders'

//work order modal context api call
export function getOrderDataById(orderId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}`,
  }
}
//order vehicles table
export function getOrderVehiclesById(orderId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/order_vehicles/byOrderObjectId?orderObjectId=${orderId}`,
  }
}
//line items table
export function getOrderServicesById(orderId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/order-services`,
  }
}
//discounts table
export function getDiscountsAttachedToOrderById(orderId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/discounts/byOrderId?orderObjectId=${orderId}`,
  }
}
//tech table
export function getTechAssignedToWorkOrder(orderId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/technicians/assigned-to-work-order?orderObjectId=${orderId}`,
  }
}
//work order history table
export function getOrderHistoryById(orderId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/orderHistory?orderObjectId=${orderId}`,
  }
}
//cancel order sub modal
export function updateOrderStatusToCanceled(
  id: string | undefined,
  reason: string,
  note: string | undefined,
) {
  let statusOfOrder =
    reason === CANCELLATION_REASONS.on_site_cancellation
      ? CANCELLATION_DB_VALUES.cancelled_on_site
      : CANCELLATION_DB_VALUES.cancelled
  return {
    method: 'POST',
    url:
      `${process.env.REACT_APP_ORIGIN}/orders/${id}/status/${statusOfOrder}?reason=${reason}` +
      (note ? `&note=${encodeURIComponent(note)}` : ''),
  }
}
//cancel order sub modal
//edit order note
export function overwriteOrderNote(orderId: string, note: string) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/note`,
    body: note,
  }
}
//send customer CC update

export function sendCustomerPaymentMethodUpdate(orderId: string) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/payment-method-update-invite`,
    body: {
      orderId,
      origin: window.location.origin,
    },
  }
}

export function getLocations() {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/locations/location`,
  }
}

export function getRescheduleQueueAssignToUsersList() {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/users/reschedule-queue-assign-to-user-list`,
  }
}

export function assignUserToOrder(orderId: string, assignedTo: string | null) {
  const body = {
    assignedTo,
  }
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/assignedTo`,
    body,
  }
}

export function updateOrderService(orderService: IOrderService) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/order_service`,
    body: orderService,
  }
}
