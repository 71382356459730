import { Box, TextField } from '@mui/material'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import generateMenuItems from '../../../../global/utils/generate-menu-items'
import {
  INVENTORY_STATUSES,
  INVENTORY_STATUSES_ARRAY,
} from '../../../../global/constants/van'
import { VanContext } from '../../../../global/context/van-context/VanContext'
import { useContext, useEffect, useState } from 'react'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
import PrimaryCheckbox from '../../../../global/components/checkbox/Checkbox'

interface IChangeOperatingStatusModalProps {
  isOpen: boolean
  handleClosed: Function
  handleSaveClicked: Function
  inventoryStatus: string
  setInventoryStatus: Function
}

const DEFAULT_SAVE_CONFIRMATION_MESSAGE =
  'Are you sure you want to save these changes?'

const OUT_OF_SERVICE_DATE_MESSAGE = [
  'Setting the ‘Out of Service Date’ field signifies the van has had a brand',
  'transfer or has been sold. This action will prevent any modifications to',
  'this van in the future.',
].join(' ')
const CHECKBOX_LABEL = 'Are you sure you want to do this?'

function ConfirmVanChangesModal({
  isOpen,
  handleClosed,
  handleSaveClicked,
  inventoryStatus,
  setInventoryStatus,
}: IChangeOperatingStatusModalProps) {
  const { vanData } = useContext(VanContext)
  const { outOfServiceDate } = vanData

  const [isConfirmedChecked, setIsConfirmedChecked] = useState(false)

  const [
    shouldDisplayOutOfServiceDateForm,
    setShouldDisplayOutOfServiceDateInformation,
  ] = useState(determineIfOutOfServiceDateFormShouldBeDisplayed)

  function determineIfOutOfServiceDateFormShouldBeDisplayed() {
    return Boolean(outOfServiceDate)
  }
  useEffect(() => {
    setShouldDisplayOutOfServiceDateInformation(
      determineIfOutOfServiceDateFormShouldBeDisplayed,
    )
  }, [isOpen, outOfServiceDate])

  const [isSaveChangesDisabled, setIsSaveChangesDisabled] = useState(
    Boolean(outOfServiceDate),
  )
  useEffect(() => {
    if (!shouldDisplayOutOfServiceDateForm) {
      setIsSaveChangesDisabled(false)
      return
    }

    const isInventoryStatusValid = Boolean(inventoryStatus)

    const isFormDataValid = isInventoryStatusValid && isConfirmedChecked
    setIsSaveChangesDisabled(!isFormDataValid)
  }, [
    isOpen,
    inventoryStatus,
    isConfirmedChecked,
    shouldDisplayOutOfServiceDateForm,
  ])

  useEffect(() => {
    if (!isOpen) {
      setInventoryStatus('')
      setIsConfirmedChecked(false)
    }
  }, [isOpen])

  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onClose={() => handleClosed()}
    >
      <Box className={styles.editVanSubModal}>
        <div className={styles.editVanSubModalPageTitle}>
          Confirm Van Changes
        </div>
        <div className={styles.editVanSubModalContent}>
          {shouldDisplayOutOfServiceDateForm ? (
            <div className={styles.requiredFieldContainer}>
              <TextField
                select
                required
                label='Inventory Status'
                name='inventoryStatus'
                className={styles.inventoryStatus}
                onChange={(e) => setInventoryStatus(e.target.value)}
                value={inventoryStatus}
              >
                {generateMenuItems(
                  INVENTORY_STATUSES_ARRAY.filter(
                    (status) => status !== INVENTORY_STATUSES.IN_INVENTORY,
                  ),
                )}
              </TextField>
            </div>
          ) : (
            <></>
          )}
        </div>
        {shouldDisplayOutOfServiceDateForm ? (
          <div className={styles.outOfServiceDateMessage}>
            {OUT_OF_SERVICE_DATE_MESSAGE}
          </div>
        ) : (
          <div className={styles.defaultSaveConfirmationMessage}>
            {DEFAULT_SAVE_CONFIRMATION_MESSAGE}
          </div>
        )}
        {shouldDisplayOutOfServiceDateForm ? (
          <div className={styles.confirmCheckbox}>
            <PrimaryCheckbox
              name='isConfirmedChecked'
              checked={isConfirmedChecked}
              label={CHECKBOX_LABEL}
              onChange={(e: any, checked: boolean) =>
                setIsConfirmedChecked(checked)
              }
            />
          </div>
        ) : (
          <></>
        )}
        <div className={styles.editVanSubModalButtons}>
          <SecondaryButton
            buttonName='Cancel'
            onClick={() => handleClosed()}
          />
          <PrimaryButton
            buttonName={
              shouldDisplayOutOfServiceDateForm
                ? 'Confirm Changes'
                : 'Save Changes'
            }
            onClick={() => handleSaveClicked()}
            disabled={isSaveChangesDisabled}
          />
        </div>
      </Box>
    </Modal>
  )
}

export default ConfirmVanChangesModal
