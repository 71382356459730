import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import LocationDropDown from '../../../../global/components/location-drop-down/LocationDropDown'
import { VanContext } from '../../../../global/context/van-context/VanContext'
import { FormHelperText } from '@mui/material'
import {
  ISSUES_STATUSES_ARRAY,
  LIVERIES_ARRAY,
  MAX_VIN_LENGTH,
  OPERATING_STATUSES,
  OPERATING_STATUSES_ARRAY,
} from '../../../../global/constants/van'
import determineIsVinValid from '../../../../global/utils/determine-is-vin-valid'
import determineIsYearValid from '../../../../global/utils/determine-is-year-valid'
import generateMenuItems from '../../../../global/utils/generate-menu-items'
import handleVinChange from '../common/handle-vin-change'
import handleYearChange from '../common/handle-year-change'
import transformDate from '../../common/transform-date'
import formatDate from '../../common/format-date'
import determineIsDateValid from '../common/determine-is-date-valid'
import determineIsStringValid from '../common/determine-is-string-valid'

interface INewVanFormComponentProps {
  operatingStatusNote: string

  setIsFormDataValid: Function
  setOperatingStatusNote: Function
}

function NewVanFormComponent({
  operatingStatusNote,

  setIsFormDataValid,
  setOperatingStatusNote,
}: INewVanFormComponentProps) {
  const { vanData, setLocation, setVanData } = useContext(VanContext)
  const {
    dotEndDate,
    inServiceDate,
    issue,
    licensePlate,
    livery,
    location,
    make,
    model,
    name,
    operatingStatus,
    operatingStatusStartDate,
    registrationEndDate,
    vin,
    year,
  } = vanData
  const locationObjectId = location?.objectId

  const [isIssueRequired, setIsIssueRequired] = useState(false)

  const [vinError, setVinError] = useState<string>('')
  const [yearError, setYearError] = useState<string>('')

  useEffect(() => {
    const tempIsIssueRequired =
      operatingStatus === OPERATING_STATUSES.INOPERATIVE
    setIsIssueRequired(tempIsIssueRequired)

    if (!tempIsIssueRequired) {
      setVanData({
        issue: undefined,
      })
    }

    const isDotEndDateValid = determineIsDateValid(dotEndDate)
    const isNameValid = determineIsStringValid(name)
    const isInServiceDateValid = determineIsDateValid(inServiceDate)
    const isLicensePlateValid = determineIsStringValid(licensePlate)
    const isLiveryValid = LIVERIES_ARRAY.includes(livery ?? '')
    const isLocationValid = determineIsStringValid(location?.objectId)
    const isMakeValid = determineIsStringValid(make)
    const isModelValid = determineIsStringValid(model)
    const isIssueValid = !tempIsIssueRequired || Boolean(issue)
    const isOperatingStatusValid = OPERATING_STATUSES_ARRAY.includes(
      operatingStatus ?? '',
    )
    const isOperatingStatusStartDateValid = determineIsDateValid(
      operatingStatusStartDate,
    )
    const isRegistrationEndDateValid = Boolean(registrationEndDate)
    const isVinValid = determineIsVinValid(vin ?? '')
    const isYearValid = determineIsYearValid(Number.parseInt(year ?? ''))

    const tempIsFormDataValid =
      isIssueValid &&
      !Boolean(vinError || yearError) &&
      isDotEndDateValid &&
      isNameValid &&
      isInServiceDateValid &&
      isLicensePlateValid &&
      isLiveryValid &&
      isLocationValid &&
      isMakeValid &&
      isModelValid &&
      isOperatingStatusStartDateValid &&
      isOperatingStatusValid &&
      isRegistrationEndDateValid &&
      isVinValid &&
      isYearValid

    setIsFormDataValid(tempIsFormDataValid)
  }, [
    dotEndDate,
    name,
    inServiceDate,
    issue,
    licensePlate,
    livery,
    location,
    make,
    model,
    operatingStatus,
    operatingStatusStartDate,
    registrationEndDate,
    vin,
    vinError,
    year,
    yearError,
  ])

  return (
    <div className={styles.formContainer}>
      <div className={styles.left}>
        <TextField
          name='name'
          label='Van Name'
          onChange={(e) => setVanData({ name: e.target.value })}
          value={name}
          required
        />
        <LocationDropDown
          onLocationChange={(val: string) => setLocation(val)}
          defaultValue={locationObjectId}
          label='Location'
          shouldIncludeDefault={false}
          required
        />
        <TextField
          select
          name='livery'
          label='Livery'
          onChange={(e) => setVanData({ livery: e.target.value })}
          value={livery}
          required
        >
          {generateMenuItems(LIVERIES_ARRAY)}
        </TextField>
        <TextField
          type='date'
          label='In Service Date'
          name='inServiceDate'
          InputLabelProps={{ shrink: true }}
          sx={{ width: '162px', display: '' }}
          onChange={(e) =>
            setVanData({ inServiceDate: transformDate(e.target.value) as Date })
          }
          value={formatDate(inServiceDate)}
          required
        />
        <TextField
          type='date'
          label='Registration End Date'
          name='registrationEndDate'
          InputLabelProps={{ shrink: true }}
          sx={{ width: '162px', display: '' }}
          onChange={(e) =>
            setVanData({
              registrationEndDate: transformDate(e.target.value) as Date,
            })
          }
          value={formatDate(registrationEndDate)}
          required
        />
        <TextField
          type='date'
          label='DOT End Date'
          name='dotEndDate'
          InputLabelProps={{ shrink: true }}
          sx={{ width: '162px', display: '' }}
          onChange={(e) =>
            setVanData({ dotEndDate: transformDate(e.target.value) as Date })
          }
          value={formatDate(dotEndDate)}
          required
        />
      </div>
      <div className={styles.middle}>
        <TextField
          name='vin'
          label='Vin'
          onChange={(e) => handleVinChange(e, setVanData, setVinError)}
          value={vin}
          error={Boolean(vinError)}
          inputProps={{ maxLength: MAX_VIN_LENGTH }}
          required
        />
        <TextField
          name='year'
          label='Year'
          type='number'
          onChange={(e) => handleYearChange(e, setVanData, setYearError)}
          value={year ?? ''}
          error={Boolean(yearError)}
          required
        />
        <TextField
          name='make'
          label='Make'
          onChange={(e) => setVanData({ make: e.target.value })}
          value={make}
          required
        />
        <TextField
          name='model'
          label='Model'
          onChange={(e) => setVanData({ model: e.target.value })}
          value={model}
          required
        />
        <TextField
          name='licensePlate'
          label='License Plate'
          onChange={(e) => setVanData({ licensePlate: e.target.value })}
          value={licensePlate}
          required
        />
        <div className={styles.errorContainer}>
          {yearError && (
            <FormHelperText
              className={styles.error}
              error
            >
              {yearError}
            </FormHelperText>
          )}
          {vinError && (
            <FormHelperText
              className={styles.error}
              error
            >
              {vinError}
            </FormHelperText>
          )}
        </div>
      </div>
      <div className={styles.middle}>
        <TextField
          type='date'
          label='Operating Status Start Date'
          name='startDate'
          InputLabelProps={{ shrink: true }}
          sx={{ width: '162px', display: '' }}
          onChange={(e) =>
            setVanData({
              operatingStatusStartDate: transformDate(e.target.value) as Date,
            })
          }
          value={formatDate(operatingStatusStartDate)}
          required
        />
        <TextField
          select
          name='operatingStatus'
          label='Operating Status'
          value={operatingStatus ?? ''}
          onChange={(e) => setVanData({ operatingStatus: e.target.value })}
          required
        >
          {generateMenuItems(OPERATING_STATUSES_ARRAY)}
        </TextField>
        {isIssueRequired && (
          <TextField
            select
            name='issue'
            label='Issue'
            value={issue}
            onChange={(e) =>
              setVanData({
                issue: e.target.value,
              })
            }
            required={isIssueRequired}
          >
            {generateMenuItems(ISSUES_STATUSES_ARRAY)}
          </TextField>
        )}
        <TextField
          sx={{ width: '100%', height: '100%' }}
          InputLabelProps={{ shrink: true }}
          multiline
          rows={10}
          label='Operating Status Note'
          value={operatingStatusNote}
          disabled={false}
          onChange={(e) => setOperatingStatusNote(e.target.value)}
        />
      </div>
    </div>
  )
}

export default NewVanFormComponent
