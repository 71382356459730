import { useEffect, useState } from 'react'
import { Column, Row } from 'react-table'
import Table from '../../../global/components/table/Table'
import { fetchData } from '../../../global/utils/fetch'
import { getHolidays } from '../../api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { IHoliday } from '../../../../../app/entities/Holiday'
import moment from 'moment'
interface IHolidaysTableProps {
  setOpen: Function
  refresh: any
}
interface ITableStateDataProps {
  data: IHoliday[]
  isLoading: boolean
}
function HolidaysTable(props: IHolidaysTableProps) {
  const { user } = useAuthenticator((context) => [context.user])
  const [tableState, setTableState] = useState<ITableStateDataProps>({
    data: [],
    isLoading: false,
  })

  useEffect(() => {
    const fetchTableData = async () => {
      setTableState((prevState) => ({
        ...prevState,
        isLoading: true,
      }))

      const data: IHoliday = await fetchData<IHoliday>(getHolidays())
      setTableState((prevState: any) => ({
        ...prevState,
        data: data,
        isLoading: false,
      }))
    }
    fetchTableData()
  }, [props.refresh])

  const columns: Column<IHoliday>[] = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Day',
      accessor: 'dayGenerated',
      sortType: (rowA, rowB) => {
        const rowAMoment = moment.utc((rowA.original as IHoliday).dayGenerated)
        const rowBMoment = moment.utc((rowB.original as IHoliday).dayGenerated)

        if (rowAMoment.isBefore(rowBMoment)) {
          return 1
        } else if (rowAMoment.isAfter(rowBMoment)) {
          return -1
        } else {
          return 0
        }
      },
    },
    {
      Header: 'Occurs yearly',
      accessor: (data: any) => {
        const { occursYearly } = data
        return occursYearly ? 'True' : 'False'
      },
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <Table
        columns={columns}
        data={(tableState.data as any) ?? []}
        isLoading={tableState.isLoading}
        isDisplayMode
        onRowClick={(row: Row) => {
          const original: any = row.original
          props.setOpen({ isOpen: true, holidayData: { ...original } })
        }}
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default HolidaysTable
