import { IVanPartial } from '../../../app/entities/Van'

export function createVan(van: IVanPartial, operatingStatusNote?: string) {
  return {
    url: [
      `${process.env.REACT_APP_ORIGIN}/vans`,
      `${
        operatingStatusNote ? `?operatingStatusNote=${operatingStatusNote}` : ''
      }`,
    ].join(''),
    method: 'POST',
    body: {
      ...van,
      created: undefined,
      images: undefined,
      lastDvir: undefined,
      lastMvir: undefined,
      lastWvir: undefined,
      objectId: undefined,
      order: undefined,
      outOfServiceDate: undefined,
      updated: undefined,
      vir: undefined,
    },
  }
}
export function updateVan(van: IVanPartial, operatingStatusNote?: string) {
  return {
    url: [
      `${process.env.REACT_APP_ORIGIN}/vans`,
      `${
        operatingStatusNote ? `?operatingStatusNote=${operatingStatusNote}` : ''
      }`,
    ].join(''),
    method: 'PUT',
    body: van,
  }
}
export function setVanToLocation(vanId: string, locationId: string) {
  return {
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}/vans/${vanId}`,
    method: 'PUT',
  }
}
export const getImageBys3ObjectKey = (s3ObjectKey: string) => {
  const url = `${process.env.REACT_APP_ORIGIN}/images/byObjectKey?s3ObjectKey=${s3ObjectKey}`
  return {
    url: url,
    method: 'GET',
  }
}
