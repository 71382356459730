export function getLocationByObjectId(locationObjectId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationObjectId}`,
  }
}
export function getVanByObjectId(vanObjectId: string) {
  return {
    url: `${process.env.REACT_APP_ORIGIN}/vans/byObjectid/${vanObjectId}`,
    method: 'GET',
  }
}
