export const getHolidays = () => {
	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/holidays`,
	}
}
export const createHoliday = (body: any) => {
	return {
		method: "POST",
		url: `${process.env.REACT_APP_ORIGIN}/holidays`,
		body,
	}
}
export const deleteHoliday = (holidayId: string) => {
	return {
		method: "DELETE",
		url: `${process.env.REACT_APP_ORIGIN}/holidays/${holidayId}`,
	}
}

export const updateHoliday = (body: any) => {
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/holidays`,
		body,
	}
}
