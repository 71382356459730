import { useAuthenticator } from '@aws-amplify/ui-react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { GONE, OK } from 'http-status'
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import PrimaryButton from '../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import ServiceDropDown from '../../../../../../global/components/service-drop-down/ServiceDropDown'
import { TYPES } from '../../../../../../global/constants/invoice'
import { STATUSES as ORDER_STATUSES } from '../../../../../../global/constants/order-status'
import { MODES, PAGES } from '../../../../../../global/constants/scheduler'
import { fetchData } from '../../../../../../global/utils/fetch'
import getUsername from '../../../../../../global/utils/getUsername'
import { AppointmentSlots } from '../../../../../../schedule/components/schedule-modal/components/appointment-slot/AppointmentSlots'
import { TireSizeQuantity } from '../../../../../../schedule/components/schedule-modal/components/choose-service/TireSizeQuantity'
import { updateOrderStatusToScheduled } from '../../../../../../schedule/components/schedule-modal/components/choose-service/api'
import { ConfirmAppointment } from '../../../../../../schedule/components/schedule-modal/components/confirm-appointment/ConfirmAppointment'
import { CustomerInformation } from '../../../../../../schedule/components/schedule-modal/components/customer-information/CustomerInformation'
import { PaymentInformation } from '../../../../../../schedule/components/schedule-modal/components/payment-information/PaymentInformation'
import { WoDetailContext } from '../../../../context/context'
import styles from '../../scheduling-modal/styles.module.css'
import setOrderState from './utils'
import isTransactionProcessing from '../../../../../../global/utils/invoice/status/is-transaction-processing'
import { Invoice } from '../../../../../../../../app/entities/Invoice'
import isTransactionSettled from '../../../../../../global/utils/invoice/status/is-transaction-settled'
import ServicePriceOverride from '../../../../../../global/components/service-price-override/ServicePriceOverride'

interface IScheduleModalProps {
  isOpen: boolean
  handleClosed: Function
  route: string
  mode: MODES
  setModalName: Function
  disableSchedulerNavigation: boolean
  isOrderCompleted: boolean
  rescheduleReason?: string
}

const finishFlow: Record<string, string> = {
  'Customer Information': PAGES.CHOOSE_A_SERVICE,
  'Choose a Service': PAGES.APPOINTMENT_SLOT,
  'Appointment Slot': PAGES.PAYMENT_INFORMATION,
  'Payment Information': PAGES.CONFIRM_APPOINTMENT,
}

export default function ScheduleContainerModal(props: IScheduleModalProps) {
  const {
    handleClosed,
    isOpen,
    route,
    mode,
    setModalName,
    disableSchedulerNavigation,
    isOrderCompleted,
    rescheduleReason,
  } = props
  const { data: order, queryOrderData } = useContext(WoDetailContext)
  const { user } = useAuthenticator((context) => [context.user])
  const [editOrderState, setEditOrderState] = useState(setOrderState(order))
  const [affirmativeDisabled, setAffirmativeDisabled] = useState(false)
  const [activeButton, setActiveButton] = useState<string>(
    route ?? PAGES.CUSTOMER_INFORMATION,
  )

  const [priceOverrideIsDisabled, setPriceOverrideIsDisabled] = useState(true)

  const isOrderStatusCompleted = order.status === ORDER_STATUSES.completed
  const hasSettledInvoice = Boolean(
    order.invoices.find((invoice: Invoice) => {
      return (
        !invoice.tip &&
        invoice.type === TYPES.COMPLETED &&
        isTransactionSettled(invoice)
      )
    }),
  )
  const hasSubmittedForSettlementInvoice = Boolean(
    order.invoices.find((invoice: Invoice) => {
      return (
        !invoice.tip &&
        invoice.type === TYPES.COMPLETED &&
        isTransactionProcessing(invoice)
      )
    }),
  )

  useEffect(() => {
    setActiveButton(route)
  }, [route, isOpen])

  useEffect(() => {
    setPriceOverrideIsDisabled(
      editOrderState.servicePriceOverride === undefined,
    )
  }, [])

  const handleButtonClick = (e: any) => {
    setActiveButton(e.target.innerHTML)
  }

  const ref = useRef<any>(null)

  const nextClick = useCallback(async () => {
    if (mode === MODES.FINISH) {
      await ref.current.handleNextClick((goToNextPage: boolean) => {
        if (goToNextPage) {
          setModalName(finishFlow[route])
        } else {
          alert(
            'Something unexpected happened. Please check your inputs and try again.',
          )
        }
      })
    }
  }, [mode, route, setModalName])

  const confirmClick = useCallback(async () => {
    try {
      setAffirmativeDisabled(true)
      // we retrieve a "raw" response because we want to check the status code
      const updateStatusToScheduledRes = await fetchData<Response>(
        updateOrderStatusToScheduled(editOrderState.orderId, {
          reason: rescheduleReason,
          username: getUsername(user),
        }),
      )

      const chosenTimeSlotIsNoLongerAvailable =
        updateStatusToScheduledRes.status === GONE
      if (chosenTimeSlotIsNoLongerAvailable) {
        alert(
          'The time slot you selected is no longer available. Please select another one.',
        )
        setActiveButton(PAGES.APPOINTMENT_SLOT)
        return
      } else if (updateStatusToScheduledRes.status !== OK) {
        throw new Error('Something went wrong. Please try again.')
      }

      await queryOrderData(order.objectId)
      handleClosed()
    } catch (error) {
      console.log(typeof error)
      console.log(Object.keys(error || {}))
      console.error('An error occurred:', error)
      alert(error)
    } finally {
      setAffirmativeDisabled(false)
    }
  }, [
    editOrderState,
    order,
    queryOrderData,
    rescheduleReason,
    setActiveButton,
    user,
    handleClosed,
  ])

  const saveClick = useCallback(async () => {
    if (ref.current === null) return
    try {
      await ref.current.handleSaveClick()
      await queryOrderData(order.objectId)
      handleClosed()
    } catch (error) {
      console.error('An error occurred:', error)
    }
  }, [queryOrderData, order, handleClosed])

  const attemptPaymentClick = useCallback(async () => {
    if (ref.current === null) return

    const paymentProcessed: boolean =
      await ref.current.handleAttemptPaymentClick()
    if (paymentProcessed) await queryOrderData(order.objectId)
  }, [queryOrderData, order])

  function goBack() {
    if (mode === MODES.FINISH) {
      const [nextRoute] =
        Object.entries(finishFlow).find(([, value]) => value === route) || []

      if (nextRoute) {
        setModalName(nextRoute)
      }
      setAffirmativeDisabled(false)
    } else {
      console.error('NO FLOW FOR THIS BUTTON WHEN NOT IN `FINISH` MODE')
    }
  }

  const [affirmativeButton, setAffirmativeButton] = useState<any>(
    <PrimaryButton
      buttonName={mode === MODES.FINISH ? 'Next' : 'Save Changes'}
      disabled={affirmativeDisabled}
      onClick={mode === MODES.FINISH ? nextClick : saveClick}
    />,
  )

  const whichAffirmativeButton = useCallback(
    (isCardOnFile?: boolean) => {
      // only visible during finish flow
      if (activeButton === PAGES.CONFIRM_APPOINTMENT) {
        setAffirmativeButton(
          <PrimaryButton
            buttonName={'Confirm'}
            disabled={affirmativeDisabled}
            onClick={confirmClick}
          />,
        )
      }
      // only visible during finish flow
      else if (mode === MODES.FINISH) {
        setAffirmativeButton(
          <PrimaryButton
            buttonName={'Next'}
            disabled={affirmativeDisabled}
            onClick={nextClick}
          />,
        )
      }
      // we will allow the rep to attempt payments if the order is completed and it does not have a paid invoice
      // and they are on the payment information page
      else if (
        activeButton === PAGES.PAYMENT_INFORMATION &&
        !hasSettledInvoice &&
        isOrderStatusCompleted
      ) {
        const shouldDisableAttempt =
          !isCardOnFile ||
          hasSubmittedForSettlementInvoice ||
          affirmativeDisabled
        setAffirmativeButton(
          <PrimaryButton
            buttonName={'Attempt Payment'}
            disabled={shouldDisableAttempt}
            onClick={attemptPaymentClick}
          />,
        )
      } else {
        const shouldDisableBecauseNoCardOnFile =
          activeButton === PAGES.PAYMENT_INFORMATION && !isCardOnFile
        const isSaveChangesDisabled =
          shouldDisableBecauseNoCardOnFile || affirmativeDisabled
        setAffirmativeButton(
          <PrimaryButton
            buttonName={'Save Changes'}
            disabled={isSaveChangesDisabled}
            onClick={saveClick}
          />,
        )
      }
    },
    [
      activeButton,
      mode,
      hasSettledInvoice,
      isOrderStatusCompleted,
      affirmativeDisabled,
      hasSubmittedForSettlementInvoice,
      saveClick,
      attemptPaymentClick,
      nextClick,
      confirmClick,
    ],
  )

  useEffect(() => {
    whichAffirmativeButton()
  }, [
    activeButton,
    mode,
    hasSettledInvoice,
    isOrderStatusCompleted,
    isOrderCompleted,
    affirmativeDisabled,
    whichAffirmativeButton,
  ])

  function isCardOnFileCallback(isCardOnFile: boolean) {
    whichAffirmativeButton(isCardOnFile)
  }

  function whichPage(activeButton: string) {
    switch (activeButton) {
      case PAGES.CUSTOMER_INFORMATION:
        return (
          <CustomerInformation
            ref={ref}
            schedulerState={editOrderState}
            setSchedulerState={setEditOrderState}
            setDisabled={setAffirmativeDisabled}
            mode={mode}
          />
        )
      case PAGES.CHOOSE_A_SERVICE:
        return (
          <TireSizeQuantity
            ref={ref}
            schedulerState={editOrderState}
            setSchedulerState={setEditOrderState}
            setDisabled={setAffirmativeDisabled}
            order={order}
            mode={mode}
          />
        )
      case PAGES.APPOINTMENT_SLOT:
        return (
          <AppointmentSlots
            ref={ref}
            schedulerState={editOrderState}
            setSchedulerState={setEditOrderState}
            setDisabled={setAffirmativeDisabled}
            mode={mode}
            rescheduleReason={rescheduleReason}
          />
        )
      case PAGES.PAYMENT_INFORMATION:
        return (
          <PaymentInformation
            ref={ref}
            schedulerState={editOrderState}
            setSchedulerState={setEditOrderState}
            setDisabled={setAffirmativeDisabled}
            mode={mode}
            isCardOnFileCallback={isCardOnFileCallback}
          />
        )
      case PAGES.CONFIRM_APPOINTMENT:
        return (
          <ConfirmAppointment
            schedulerState={editOrderState}
            setSchedulerState={setEditOrderState}
            setDisabled={setAffirmativeDisabled}
            mode={mode}
          />
        )
    }
  }

  function closeModalHandler() {
    setActiveButton(PAGES.CUSTOMER_INFORMATION)
    handleClosed()
  }

  function getNavButtonDisabledStyles(): CSSProperties | undefined {
    return isOrderCompleted
      ? { color: '#767676', pointerEvents: 'none' }
      : { color: '#d70000' }
  }

  const displayConfirmApp =
    order.status === ORDER_STATUSES.quote && order.tireRackOrders
  return (
    <div>
      <Modal open={isOpen}>
        <Box className={styles.scheduleModal}>
          <div className={`${styles.scheduleModalPageTitle} font--bold`}>
            {activeButton}
            {activeButton === PAGES.CHOOSE_A_SERVICE && (
              <>
                <ServiceDropDown
                  mode={MODES.EDIT}
                  defaultValue={editOrderState.service}
                  onServiceChange={(newValue: string) => {
                    setEditOrderState((prevState) => ({
                      ...prevState,
                      service: newValue,
                    }))
                  }}
                />
                <ServicePriceOverride
                  schedulerState={editOrderState}
                  setSchedulerState={setEditOrderState}
                  priceOverrideIsDisabled={priceOverrideIsDisabled}
                  setPriceOverrideIsDisabled={setPriceOverrideIsDisabled}
                />
              </>
            )}
          </div>
          <div
            style={{
              minHeight: '500px',
              width: '100%',
              overflowX: 'auto',
              height: '1040px',
            }}
          >
            {whichPage(activeButton)}
          </div>

          <div className={styles.bottomNavBar}>
            <div className={styles.buttonContainer}>
              {mode !== MODES.FINISH && (
                <div
                  className={`${styles.pagesLinks} ${disableSchedulerNavigation ? styles.buttonDisabled : null}`}
                >
                  <button
                    className={`${styles.navLink} ${activeButton === PAGES.CUSTOMER_INFORMATION ? styles.active : null}`}
                    disabled={isOrderCompleted}
                    style={getNavButtonDisabledStyles()}
                    onClick={(e) => handleButtonClick(e)}
                  >
                    Customer Information
                  </button>
                  <button
                    className={`${styles.navLink} ${activeButton === PAGES.CHOOSE_A_SERVICE ? styles.active : null}`}
                    disabled={isOrderCompleted}
                    style={getNavButtonDisabledStyles()}
                    onClick={(e) => handleButtonClick(e)}
                  >
                    Choose a Service
                  </button>
                  <button
                    className={`${styles.navLink} ${activeButton === PAGES.APPOINTMENT_SLOT ? styles.active : styles.buttonDisabled}`}
                    disabled={true}
                    style={{ color: '#767676', pointerEvents: 'none' }}
                    onClick={(e) => handleButtonClick(e)}
                  >
                    Appointment Slot
                  </button>
                  <button
                    className={`${styles.navLink} ${activeButton === PAGES.PAYMENT_INFORMATION ? styles.active : null}`}
                    onClick={(e) => handleButtonClick(e)}
                  >
                    Payment Information
                  </button>
                  {displayConfirmApp && (
                    <button
                      className={`${styles.navLink} ${activeButton === PAGES.CONFIRM_APPOINTMENT ? styles.active : null}`}
                      onClick={(e) => handleButtonClick(e)}
                    >
                      Confirm Appointment
                    </button>
                  )}
                </div>
              )}
              <div className={styles.flexButtons}>
                <div className={styles.flexGroupButtons}>
                  {mode === MODES.FINISH &&
                    activeButton !== 'Confirm Appointment' && (
                      <SecondaryButton
                        buttonName='Back'
                        onClick={() => goBack()}
                      />
                    )}
                  <SecondaryButton
                    buttonName='Cancel'
                    onClick={() => closeModalHandler()}
                  />
                </div>
                {affirmativeButton}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
