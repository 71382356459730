import { ReactNode, useState } from 'react'
import styles from '../styles.module.css'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import CircularProgress from '@mui/material/CircularProgress'
import { getBrands } from '../../api'
import { IVehicleInformationSelectProps } from '../VehicleInformationForm'
import { IBrand } from '../../../../../../../../../app/entities/Brand'

const selectProps = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 500,
        width: 250,
      },
    },
  },
}

export default function MakeInformationSelect(
  props: IVehicleInformationSelectProps,
) {
  let menuItems = [] as ReactNode
  const { formData } = props

  let { data, isLoading } = useFetch<IBrand[]>(getBrands())

  const [selectedValue, setSelectedValue] = useState({
    name: formData.make,
    objectId: formData.brandId,
  })

  if (data) {
    menuItems = data.map((item: any) => {
      let menuItemValue = {
        name: item.name,
        objectId: item.objectId,
      }
      return (
        <MenuItem
          key={item.objectId}
          value={JSON.stringify(menuItemValue)}
        >
          {item.name}
        </MenuItem>
      )
    })
  }

  if (isLoading) {
    return <CircularProgress size={20} />
  }

  return (
    <TextField
      className={styles.textfieldStyles}
      label={props.label}
      select
      SelectProps={selectProps}
      value={JSON.stringify(selectedValue)}
      onChange={(e) => {
        const selectedValue = JSON.parse(e.target.value)
        setSelectedValue(selectedValue)
        props.onChange({
          ...props.formData,
          make: selectedValue.name,
          brandId: selectedValue.objectId,
          year: '',
          model: '',
          variant: '',
        })
      }}
    >
      {menuItems}
    </TextField>
  )
}
