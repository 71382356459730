import React, { useContext, useEffect } from 'react'
import styles from './styles.module.css'
import { ILocationFilters } from '../../../work-orders/components/main/WorkOrdersMain'
import LocationDropDown from '../../../global/components/location-drop-down/LocationDropDown'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import NewVanModal from '../../modal/new-van-modal/NewVanModal'
import { IRefreshCounterProps } from '../VansMain'
import { PERMISSIONS } from '../../../global/constants/permissions'
import hasPermission from '../../../global/utils/user/has-permission'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import { VanContext } from '../../../global/context/van-context/VanContext'
import moment from 'moment'

interface IVanPageHeaderProps {
  locationFilters: ILocationFilters
  refresh: IRefreshCounterProps
}
function VansPageHeader(props: IVanPageHeaderProps) {
  const { user } = useContext(UserContext)
  const { clearVanData, setVanData } = useContext(VanContext)
  const { setLocationFilter } = props.locationFilters
  const { refreshCounter, setRefreshCounter } = props.refresh

  const [open, setOpen] = React.useState(false)

  const isNewVanButtonDisabled = !hasPermission(user).allow(
    PERMISSIONS.VANS.CATEGORY,
    PERMISSIONS.VANS.ENTRIES.NEW_VAN_BUTTON.NAME,
  )

  function handleOpen() {
    setOpen(true)

    // Set default values for new van
    setVanData({
      inServiceDate: moment().toDate(),
      operatingStatusStartDate: moment().toDate(),
    })
  }

  function handleClosed() {
    setOpen(false)
    clearVanData()
  }

  return (
    <>
      {open && (
        <NewVanModal
          isOpen={open}
          handleClosed={handleClosed}
          refresh={{ refreshCounter, setRefreshCounter }}
        />
      )}
      <div className={styles.vanPageHeaderContainer}>
        <div className={styles.inputsContainer}>
          <LocationDropDown
            onLocationChange={(val: string) => {
              setLocationFilter(val)
            }}
          />
        </div>
        <div className={styles.inputsContainer}>
          <PrimaryButton
            buttonName='New Van'
            onClick={handleOpen}
            disabled={isNewVanButtonDisabled}
          />
        </div>
      </div>
    </>
  )
}

export default VansPageHeader
