import { Checkbox } from '@mui/material'
import styles from '../../styles.module.css'
import { useState } from 'react'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import { fetchData } from '../../../../global/utils/fetch'
import { updateOrderStatusToCanceledNoAuth } from '../../../api'
import { IOrder } from '../../../../../../app/entities/Order'
import { IAlertStatusProps } from '../../../../global/components/toast-alert/ToastNotification'

interface ConfirmCancelProps {
  alertStatus: IAlertStatusProps
  handleClose: Function
  isOpen: boolean
  orderObjectId: string
  setAlertStatus: Function
}

export default function ConfirmCancel(props: ConfirmCancelProps) {
  const { alertStatus, handleClose, isOpen, orderObjectId, setAlertStatus } =
    props

  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function handleCancelOrder() {
    try {
      setIsLoading(true)
      await fetchData<IOrder>(updateOrderStatusToCanceledNoAuth(orderObjectId))
      handleClose()
    } catch (err) {
      console.error(err)
      setAlertStatus({
        ...alertStatus,
        isOpen: true,
        message: 'Error canceling appointment!',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isOpen && (
        <div className={styles.inputsContainer}>
          <div className={styles.subModalHeader}>Cancel Appointment</div>
          <div className={styles.checkBoxContainer}>
            <Checkbox onChange={() => setIsChecked(!isChecked)} />
            <div>Yes I want to cancel this order</div>
          </div>
          <div className={styles.buttonContainer}>
            <SecondaryButton
              buttonName='Cancel'
              onClick={() => {
                handleClose()
              }}
            />
            <PrimaryButton
              buttonName='Confirm'
              disabled={!isChecked || isLoading}
              onClick={handleCancelOrder}
            />
          </div>
        </div>
      )}
    </>
  )
}
