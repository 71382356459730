import { IHubSpokeStoreMappingPartial } from '../../../../../../../../bi/entities/HubSpokeStoreMapping'
import tableStyles from '../../../../../../global/components/table/styles.module.css'
import buttonStyles from '../../styles.module.css'
import TextField from '@mui/material/TextField'
import { useState, ChangeEvent } from 'react'

interface IHubSpokeStoreMappingRowProps {
  handleCancel: Function
  handleSaveNewRow: Function
}

const COLUMN_NAMES = [
  'hubStoreId',
  'spokeStoreId',
  'spokeSapId',
  'startDate',
  'endDate',
] as ('hubStoreId' | 'spokeStoreId' | 'startDate' | 'endDate')[]

export default function CreateHubSpokeStoreMapping(
  props: IHubSpokeStoreMappingRowProps,
) {
  const [newHubSpokeStoreMapping, setNewHubSpokeStoreMapping] = useState<{
    hubStoreId: string
    spokeStoreId: string
    spokeSapId: string
    startDate: undefined | Date
    endDate: undefined | Date
  }>({
    startDate: undefined,
    endDate: undefined,
    hubStoreId: '',
    spokeStoreId: '',
    spokeSapId: '',
  })

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setNewHubSpokeStoreMapping({
      ...newHubSpokeStoreMapping,
      [e.target.name]: e.target.value,
    })
  }

  function addNewHubSpokeStoreMapping(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) {
    e.preventDefault()
    props.handleSaveNewRow(newHubSpokeStoreMapping)
  }

  const rows = COLUMN_NAMES.map((columnName) => {
    return (
      <td className={tableStyles.bodyRowCell}>
        <TextField
          size='small'
          name={`${columnName}`}
          value={newHubSpokeStoreMapping[`${columnName}`]}
          sx={{ minWidth: 100 }}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
        />
      </td>
    )
  })

  return (
    <tr className={tableStyles.bodyRow}>
      {rows}
      <td className={tableStyles.bodyRowCell}>
        <div className={buttonStyles.buttonCell}>
          <div className={buttonStyles.buttonTextBox}>
            <a
              className={buttonStyles.buttonText}
              onClick={addNewHubSpokeStoreMapping}
            >
              Add
            </a>
          </div>
          <div className={buttonStyles.line}></div>
          <div className={buttonStyles.buttonTextBox}>
            <a
              className={buttonStyles.buttonText}
              onClick={() => props.handleCancel()}
            >
              Cancel
            </a>
          </div>
        </div>
      </td>
    </tr>
  )
}
