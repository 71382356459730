import { UpdateOrderStatusToScheduledOptions } from '../../../schedule/components/schedule-modal/components/choose-service/api'

export function getOrderByTrNumber(
  trOrderNumber: string,
  orderStatus?: string,
) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/by-tire-rack-order-number/${trOrderNumber}${orderStatus ? `?orderStatus=${orderStatus}` : ''}`,
  }
}
export function setShouldRequestPaymentAfterScheduling(
  objectId: string,
  shouldRequestPaymentAfterScheduling: boolean,
) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${objectId}/should-request-payment-after-scheduling/${shouldRequestPaymentAfterScheduling}`,
  }
}
export function setTimeSlot(body: any, objectId: string) {
  return {
    method: 'POST',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/orders/${objectId}/time-slot`,
  }
}
export function updateOrderStatusToScheduled(
  orderId: string,
  options: UpdateOrderStatusToScheduledOptions = {},
) {
  const { availabilityCalendarSlot, reason, username } = options

  const params = new URLSearchParams()
  if (reason) params.append('reason', reason)
  if (username) params.append('username', username)

  return {
    method: 'POST',
    url:
      `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/status/scheduled` +
      (params.toString() ? `?${params.toString()}` : ''),
    body: availabilityCalendarSlot ? availabilityCalendarSlot : {},
    returnRaw: true,
  }
}
