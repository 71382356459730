// This file is duplicated at app/constants/van.ts
import moment from 'moment'

const LIVERIES = {
  ASAP: 'ASAP',
  ATMI: 'ATMI',
  DTMI: 'DTMI',
  TRMI: 'TRMI',
}
const LIVERIES_ARRAY = Object.values(LIVERIES)

const INVENTORY_STATUSES = {
  BRAND_TRANSFERRED: 'Brand Transferred',
  IN_INVENTORY: 'In Inventory',
  SOLD: 'Sold',
}
const INVENTORY_STATUSES_ARRAY = Object.values(INVENTORY_STATUSES)

const ISSUES = {
  'ENGINE PROBLEMS': 'Engine problems',
  'TRANSMISSION ISSUES': 'Transmission issues',
  'ELECTRICAL PROBLEMS': 'Electrical problems',
  'BRAKE SYSTEM FAILURES': 'Brake system failures',
  'SUSPENSION AND STEERING ISSUES': 'Suspension and steering issues',
  'TIRE ISSUES': 'Tire issues',
  'COOLING SYSTEM FAILURES': 'Cooling system failures',
  'EXHAUST SYSTEM PROBLEMS': 'Exhaust system problems',
  'FUEL SYSTEM ISSUES': 'Fuel system issues',
  'HVAC SYSTEM FAILURES': 'HVAC system failures',
  INVERTER: 'Inverter',
  BALANCER: 'Balancer',
  MOUNTER: 'Mounter',
  'AUTO INFLATOR': 'Auto Inflator',
  SOLENOID: 'Solenoid',
  'AIR LINES': 'Air Lines',
  OTHER: 'Other',
}
const ISSUES_STATUSES_ARRAY = Object.values(ISSUES)

const OPERATING_STATUSES = {
  ACTIVE: 'Active',
  INOPERATIVE: 'Inoperative',
  STANDBY: 'Standby',
}
const OPERATING_STATUSES_ARRAY = Object.values(OPERATING_STATUSES)

const MAX_YEAR = moment().add(1, 'year').year()
const MIN_YEAR = 2000 // arbitrary

/* Determines if a string is 17 characters long and contains only valid
 * characters for a VIN (0-9, A-H, J-N, P, R-Z).
 */
const VAN_VIN_REGEX = /^[A-HJ-NPR-Z0-9]{17}$/
const MAX_VIN_LENGTH = 17

export {
  INVENTORY_STATUSES,
  INVENTORY_STATUSES_ARRAY,
  ISSUES,
  ISSUES_STATUSES_ARRAY,
  LIVERIES,
  LIVERIES_ARRAY,
  MAX_YEAR,
  MIN_YEAR,
  MAX_VIN_LENGTH,
  OPERATING_STATUSES,
  OPERATING_STATUSES_ARRAY,
  VAN_VIN_REGEX,
}
