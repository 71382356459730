import * as api from '../../../api'
import { ReportButtonProps } from '../DownloadReportsModal'

const newInvoiceReportsButtons: ReportButtonProps[] = [
  {
    buttonName: 'Unpaid Balances / Bad Debt Report',
    reportName: 'rpt_unpaidBalancesBadDebt',
    reportCall: api.unpaidBalancesBadDebt,
  },
  {
    buttonName: 'Inventory Report',
    reportName: 'rpt_inventoryReport',
    reportCall: api.inventoryReport,
  },
]

const newInvoiceReportsButtonsWithDates: ReportButtonProps[] = [
  {
    buttonName: 'Monthly Service and Tax Amounts Report',
    reportName: 'rpt_monthlyServiceAndTaxAmounts',
    reportCall: api.monthlyServiceAndTaxAmounts,
  },
  {
    buttonName: 'Prior Month Paid in Current Month Report',
    reportName: 'rpt_priorMonthPaidInCurrentMonth',
    reportCall: api.priorMonthPaidInCurrentMonth,
  },
  {
    buttonName: 'Receivables Report',
    reportName: 'rpt_receivables',
    reportCall: api.unpaidBalancesBadDebt,
  },
  {
    buttonName: 'Refunds Report',
    reportName: 'rpt_refunds',
    reportCall: api.refunds,
  },
  {
    buttonName: 'Tax Corrections Report',
    reportName: 'rpt_taxCorrections',
    reportCall: api.taxCorrections,
  },
  {
    buttonName: 'Tire Fees Report',
    reportName: 'rpt_tireFees',
    reportCall: api.tireFeesReport,
  },
  {
    buttonName: 'Tire Sales Report',
    reportName: 'rpt_tireSales',
    reportCall: api.tireSalesReport,
  },
  {
    buttonName: 'Transaction Report',
    reportName: 'rpt_transactionReport',
    reportCall: api.transactionReport,
  },
]

export { newInvoiceReportsButtons, newInvoiceReportsButtonsWithDates }
