import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import { IRolePartial } from '../../../../app/entities/Role'
import { CircularProgress } from '@mui/material'
import SecondaryButton from '../../global/components/buttons/secondary-button/SecondaryButton'
import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import { useContext, useState } from 'react'
import PrimaryCheckbox from '../../global/components/checkbox/Checkbox'
import { fetchData } from '../../global/utils/fetch'
import { deleteRole } from '../api'
import { ToastNotificationContext } from '../../global/context/toast-context/ToastNotificationContext'

interface IAdjustInvoiceProps {
  isOpen: boolean
  handleClosed: any // React.MouseEvent or takes a boolean to
  // represent if the role was deleted
  roleData: IRolePartial
}

export default function DeleteConfirmationModal({
  isOpen,
  handleClosed,
  roleData,
}: IAdjustInvoiceProps) {
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  async function handleDelete() {
    setIsLoading(true)
    try {
      setIsLoading(true)
      await fetchData(deleteRole(roleData.objectId!))
      handleClosed(true) // passed obj ignored
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Role successfully deleted!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: [
          'There has been an error attempting to delete this Role.',
          'Please ensure that the Role is not assigned to any Users',
          'and try again. If the issue persists, please contact support.',
        ].join(' '),
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClosed}
      >
        <Box className={styles.deleteRoleModal}>
          <h1>Delete Confirmation</h1>
          <i>
            All Users must be disassociated from this role before it can be
            deleted.
          </i>
          <br />
          <div className={styles.checkBoxContainer}>
            <PrimaryCheckbox
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.target.checked)
              }}
            />
            <p>Are you sure you want to delete the '{roleData.name}' Role?</p>
          </div>
          <div
            className={`${styles.deleteRoleModalPageTitle} font--bold`}
          ></div>
          <div className={styles.buttonContainer}>
            {isLoading && (
              <div className={styles.progressContainer}>
                <CircularProgress />
              </div>
            )}
            <SecondaryButton
              buttonName='Cancel'
              onClick={handleClosed}
            />
            <PrimaryButton
              disabled={isLoading || !isChecked}
              buttonName='Delete'
              onClick={() => handleDelete()}
            />
          </div>
        </Box>
      </Modal>
    </div>
  )
}
