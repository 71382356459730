import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { fetchData } from '../../../global/utils/fetch'
import { getOrderByTrNumber } from './api'
import setOrderState from '../../../work-orders/components/work-order-detail/components/scheduling-modal/container-modal/utils'
import {
  ISchedulerStateProps,
  initialStateProps,
} from '../../../schedule/components/schedule-modal/components/container-modal/initialData'
import { IOrder } from '../../../../../app/entities/Order'
import ErrorDisplay from '../../../global/components/error/ErrorDisplay'
import TrScheduler from '../components/calendar/TrScheduler'
import CircularProgress from '@mui/material/CircularProgress'
import TrNumberSearchBar from '../components/tr-number-search/TrNumberSearchBar'
import CustomerInfo from '../components/customer-info/CustomerInformation'
import UpdateAddress from '../components/update-address/UpdateAddress'
import { STATUSES } from '../../../global/constants/order-status'

const SCHEDULABLE_STATUSES = [
  STATUSES.en_route,
  STATUSES.reschedule,
  STATUSES.quote,
]

const QUERY_ORDER_DATA_ERRORS = {
  ORDER_NOT_FOUND: 'Order not found. Try again.',
  ORDER_NOT_IN_SCHDULABLE_STATUS: `Order is not Schedulable. Try again.`,
}

export default function AppointmentBookingMain() {
  const [schedulerState, setSchedulerState] = useState<ISchedulerStateProps>({
    ...initialStateProps,
  })
  const [trNumber, setTrNumber] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false)
  const [isNextDisabled, setNextDisabled] = useState<boolean | undefined>(
    undefined,
  )

  useEffect(() => {
    if (trNumber === '') {
      setSchedulerState({ ...initialStateProps })
      setSearchPerformed(false)
    }
  }, [trNumber])

  async function fetchOrderData() {
    setSearchPerformed(true)
    try {
      setIsLoading(true)
      const orderData = await fetchData<IOrder>(
        getOrderByTrNumber(trNumber, STATUSES.quote),
      )
      const orderNotFound = Array.isArray(orderData) && orderData.length === 0
      const shouldDisplayErrorScreen = !SCHEDULABLE_STATUSES.includes(
        orderData.status ?? '',
      )
      if (orderNotFound) {
        //returns 204 empty array if order not found
        setError(QUERY_ORDER_DATA_ERRORS.ORDER_NOT_FOUND)
        setSearchPerformed(false)
        setTrNumber('')
      } else if (shouldDisplayErrorScreen) {
        setError(QUERY_ORDER_DATA_ERRORS.ORDER_NOT_IN_SCHDULABLE_STATUS)
        setSearchPerformed(false)
        setTrNumber('')
      } else {
        const transformedData = setOrderState(orderData)
        setSchedulerState({
          ...transformedData,
          shouldRequestPaymentAfterScheduling: true,
        })
        setError(undefined)
      }
    } catch (error) {
      console.error(error)
      setError('An error occurred while fetching the order.')
    } finally {
      setIsLoading(false)
    }
  }

  const displayContentIfSearchPerformed =
    searchPerformed && !error && schedulerState && !isLoading

  function renderContent() {
    if (isLoading) {
      return (
        <div className={styles.progressContainer}>
          <CircularProgress />
        </div>
      )
    } else if (error) {
      return <ErrorDisplay error={error} />
    } else if (displayContentIfSearchPerformed) {
      return (
        <>
          <div>
            <UpdateAddress
              schedulerState={schedulerState}
              fetchOrderData={fetchOrderData}
              setNextDisabled={setNextDisabled}
            />
          </div>
          <div className={styles.schedulerWrapperContainer}>
            <TrScheduler
              schedulerState={schedulerState}
              setSchedulerState={setSchedulerState}
              isAddressUpdating={isNextDisabled}
              setTrNumber={setTrNumber}
            />
          </div>
          <div>{error}</div>
        </>
      )
    }
    return null
  }

  return (
    <div className={styles.appointmentBookingContainer}>
      <div>
        Enter a Tire Rack Order number below to book an available appointment
      </div>
      <div className={styles.infoSearchBarContainer}>
        <TrNumberSearchBar
          trNumber={trNumber}
          setTrNumber={setTrNumber}
          fetchOrderData={fetchOrderData}
        />
        {displayContentIfSearchPerformed && (
          <CustomerInfo schedulerState={schedulerState} />
        )}
      </div>
      {renderContent()}
    </div>
  )
}
