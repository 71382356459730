import { Box, CircularProgress, Modal } from '@mui/material'
import { MouseEventHandler, useEffect, useState } from 'react'
import { AvailabilityCalendarSlot } from '../../../../../../app/types/scheduling'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
import { fetchData } from '../../../../global/utils/fetch'
import {
  timestampToDateTime,
  timestampToTime,
} from '../../../../schedule/components/schedule-modal/components/confirm-appointment/utils'
import { ISchedulerStateProps } from '../../../../schedule/components/schedule-modal/components/container-modal/initialData'
import styles from './styles.module.css'
import { GONE, OK } from 'http-status'
import { updateOrderStatusToScheduled } from '../../main/api'

interface IConfirmTrSalesModalProps {
  isOpen: boolean
  handleClosed: MouseEventHandler
  schedulerState: ISchedulerStateProps
  setSchedulerState: Function
  setTrNumber: Function
}

function ConfirmTrSalesOrderModal(props: IConfirmTrSalesModalProps) {
  const { schedulerState, setSchedulerState, setTrNumber } = props
  const { customerInformation, selectedAvailabilityCalendarSlot, orderId } =
    schedulerState
  const {
    address,
    city,
    email,
    firstName,
    lastName,
    make,
    model,
    phone,
    state,
    year,
    zipCode,
  } = customerInformation
  const timeSlot = selectedAvailabilityCalendarSlot as AvailabilityCalendarSlot
  const { fromTime, toTime } = timeSlot
  const [isLoading, setIsLoading] = useState(false)

  function handleClose(e: any) {
    props.handleClosed(e)
  }

  async function handleConfirmTrSale(e: any) {
    setIsLoading(true)
    try {
      const updateStatusToScheduledRes = await fetchData<Response>(
        updateOrderStatusToScheduled(orderId),
      )
      const chosenTimeSlotIsNoLongerAvailable =
        updateStatusToScheduledRes.status === GONE
      if (chosenTimeSlotIsNoLongerAvailable) {
        setSchedulerState((prevState: ISchedulerStateProps) => ({
          ...prevState,
          selectedAvailabilityCalendarSlot: undefined,
        }))
        alert(
          'The time slot you selected is no longer available. Please select another one.',
        )
        props.handleClosed(e)
        return
      } else if (updateStatusToScheduledRes.status !== OK) {
        console.error('An error occurred:', updateStatusToScheduledRes)
        throw new Error('Something went wrong. Please try again.')
      }

      setSchedulerState((prevState: ISchedulerStateProps) => ({
        ...prevState,
        status: 'scheduled',
      }))
    } catch (error) {
      console.error('An error occurred:', error)
    } finally {
      setIsLoading(false)
      setTrNumber('')
      props.handleClosed(e)
    }
  }

  return (
    <div>
      <Modal
        open={props.isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={styles.trSalesModal}>
          <>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <div className={styles.confirmationModalHeader}>
                  <div
                    className={`${styles.confirmationModalPageTitle} font--bold`}
                  >
                    Confirm Mobile Installation Appointment
                  </div>
                  <div className={styles.information}>
                    <div
                      style={{ fontWeight: 600 }}
                      className={styles.customerInformation}
                    >
                      Customer Information:
                    </div>
                    <div>{`${firstName} ${lastName.charAt(0)}`}</div>
                    <div>{`${year} ${make} ${model}`}</div>
                    <div>{`${phone}`}</div>
                    <div>{`${email}`}</div>
                    <div
                      style={{ fontWeight: 600 }}
                      className={styles.customerInformation}
                    >
                      Address:
                    </div>
                    <div>
                      {[address, city, state, zipCode]
                        .filter(Boolean)
                        .join(', ')}
                    </div>
                    <div
                      style={{ fontWeight: 600 }}
                      className={styles.customerInformation}
                    >
                      Time Slot:
                    </div>
                    <div>{`${timestampToDateTime(fromTime)}`}</div>
                    <div>{`${timestampToTime(fromTime)} - ${timestampToTime(toTime)}`}</div>
                    <div
                      className={styles.customerInformation}
                      style={{ fontSize: 16, fontStyle: 'italic' }}
                    >
                      {'By clicking below, the customer will be sent an '}
                      {'email and text with their Secure Link to pay for '}
                      {'their installation.'}
                    </div>
                  </div>
                </div>
                <div className={styles.confirmationModalHeaderButtons}>
                  <SecondaryButton
                    buttonName='Cancel'
                    onClick={handleClose}
                  />
                  <PrimaryButton
                    buttonName='Confirm'
                    onClick={handleConfirmTrSale}
                  />
                </div>
              </>
            )}
          </>
        </Box>
      </Modal>
    </div>
  )
}

export default ConfirmTrSalesOrderModal
