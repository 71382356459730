import { TextField } from '@mui/material'
import { MS_IN_DAY } from '../../../global/constants/date'
import { formatDateYyyyMmDd } from '../../../global/utils/date/date-utils-yyyy-mm-dd'
import styles from './styles.module.css'

interface IDashboardHeaderProps {
  queryDate: Date
  setQueryDate: Function
}
export default function DashboardMainHeader(props: IDashboardHeaderProps) {
  const { queryDate, setQueryDate } = props

  function setDate(input: 'prev' | 'next') {
    const shouldSubtractOneDay = input === 'prev'
    const msOffset = (shouldSubtractOneDay ? -1 : 1) * MS_IN_DAY
    setQueryDate(new Date(queryDate.getTime() + msOffset))
  }

  function handleChange(e: any) {
    const { value } = e.target
    setQueryDate(new Date(value))
  }

  return (
    <div className={styles.dashboardHeaderContainer}>
      <div className={styles.inputsContainer}>
        <div className={styles.navButtonContainer}>
          <button
            className={styles.navButton}
            onClick={() => setDate('prev')}
          >
            <span className={styles.expandCharacter}>{`<`}</span>
          </button>
          <TextField
            label='Select a Date'
            type='date'
            value={formatDateYyyyMmDd(queryDate)}
            InputLabelProps={{ shrink: true }}
            sx={{ width: 130 }}
            onChange={handleChange}
          />
          <button
            className={styles.navButton}
            onClick={() => setDate('next')}
          >
            <span className={styles.expandCharacter}>{`>`}</span>
          </button>
        </div>
      </div>
    </div>
  )
}
