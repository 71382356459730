import { useEffect, useState } from 'react'
import PrimaryCheckbox from './Checkbox'

interface ICheckboxWrapperProps {
  isInitiallyChecked: boolean
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  name?: string
  disabled?: boolean
}

function CheckboxWrapper(props: ICheckboxWrapperProps) {
  const { isInitiallyChecked, onChange, name, disabled } = props

  const [checked, setChecked] = useState(isInitiallyChecked)
  useEffect(() => {
    setChecked(isInitiallyChecked)
  }, [isInitiallyChecked])

  return (
    <PrimaryCheckbox
      name={name}
      checked={checked}
      onChange={(event, checked) => {
        setChecked(checked)
        if (onChange) {
          onChange(event, checked)
        }
      }}
      disabled={disabled}
    />
  )
}

export default CheckboxWrapper
