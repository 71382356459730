import { useState, useRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { CustomerInformation } from '../customer-information/CustomerInformation'
import { AppointmentSlots } from '../appointment-slot/AppointmentSlots'
import { PaymentInformation } from '../payment-information/PaymentInformation'
import { ConfirmAppointment } from '../confirm-appointment/ConfirmAppointment'
import { fetchData } from '../../../../../global/utils/fetch'
import { updateOrderStatusToScheduled } from '../choose-service/api'
import { TireSizeQuantity } from '../choose-service/TireSizeQuantity'
import ServiceDropDown from '../../../../../global/components/service-drop-down/ServiceDropDown'
import { ISchedulerStateProps, initialStateProps } from './initialData'
import { routes } from './scheduleRoutes'
import { MODES } from '../../../../../global/constants/scheduler'
import { GONE, OK } from 'http-status'
import ServicePriceOverride from '../../../../../global/components/service-price-override/ServicePriceOverride'
import { IOrder } from '../../../../../../../app/entities/Order'
interface IScheduleModalProps {
  isOpen: boolean
  handleClosed: Function
  chosenAddress: google.maps.GeocoderResult | null
}

export interface ITiresProps {
  frontCount: number
  rearCount: number
  frontTire: ITireProps
  rearTire: ITireProps
  objectId?: string | undefined
}

export interface ITireProps {
  aspectRatio: string
  width: string
  diameter: string
  objectId?: string | undefined
}

export interface ITimeSlot {
  type: string
  fromTime: number
  toTime: number
  available?: string | null
}

export interface IScheduleModalComponentProps {
  setDisabled: Function
  setSchedulerState: Function
  schedulerState: ISchedulerStateProps
  mode: MODES
  isCardOnFileCallback?: Function
  toggleToastError?: Function
  rescheduleReason?: string
  setRouteIndex?: Function
  viewOnly?: boolean
  order?: IOrder
}

export default function ScheduleModal(props: IScheduleModalProps) {
  const [routeIndex, setRouteIndex] = useState(0)
  const [nextDisabled, setNextDisabled] = useState(true)
  const [schedulerState, setSchedulerState] =
    useState<ISchedulerStateProps>(initialStateProps)
  const [priceOverrideIsDisabled, setPriceOverrideIsDisabled] = useState(true)

  const ref = useRef<any>(null)
  const forwardClick = () => {
    if (ref.current !== null) {
      ref.current.handleNextClick((forward: boolean) => {
        if (forward) {
          const newRouteIndex = routeIndex + 1
          setRouteIndex(newRouteIndex)
        }
      })
    }
  }

  const backClick = () => {
    const newRouteIndex = routeIndex - 1
    setRouteIndex(newRouteIndex)
  }

  const confirmClick = async () => {
    const { selectedAvailabilityCalendarSlot } = schedulerState
    if (!selectedAvailabilityCalendarSlot) {
      alert('Please select a time slot')
      return
    }

    try {
      setNextDisabled(true)
      // we retrieve a "raw" response because we want to check the status code
      const updateStatusToScheduledRes = await fetchData<Response>(
        updateOrderStatusToScheduled(schedulerState.orderId, {
          availabilityCalendarSlot: selectedAvailabilityCalendarSlot,
        }),
      )

      const chosenTimeSlotIsNoLongerAvailable =
        updateStatusToScheduledRes.status === GONE
      if (chosenTimeSlotIsNoLongerAvailable) {
        // reset the selected time slot
        setSchedulerState((prevState) => ({
          ...prevState,
          selectedAvailabilityCalendarSlot: undefined,
        }))

        alert(
          'The time slot you selected is no longer available. Please select another one.',
        )
        setRouteIndex(2) // go back to the time slot selection page
        return
      } else if (updateStatusToScheduledRes.status !== OK) {
        console.error('An error occurred:', updateStatusToScheduledRes)
        throw new Error('Something went wrong. Please try again.')
      }

      setPriceOverrideIsDisabled(true)
      props.handleClosed()
    } catch (error) {
      setNextDisabled(false)
      alert(error)
    }
  }

  async function onScheduleModalClose() {
    setPriceOverrideIsDisabled(true)
    props.handleClosed()
  }

  const renderComponent = (path: string) => {
    switch (path) {
      case 'customer-info':
        return (
          <CustomerInformation
            schedulerState={schedulerState}
            setSchedulerState={setSchedulerState}
            ref={ref}
            setDisabled={setNextDisabled}
            mode={MODES.SCHEDULER}
          />
        )

      case 'size-quantity':
        return (
          <TireSizeQuantity
            schedulerState={schedulerState}
            setSchedulerState={setSchedulerState}
            ref={ref}
            setDisabled={setNextDisabled}
            mode={MODES.SCHEDULER}
          />
        )
      case 'appointment-slots':
        return (
          <AppointmentSlots
            schedulerState={schedulerState}
            ref={ref}
            setSchedulerState={setSchedulerState}
            setDisabled={setNextDisabled}
            mode={MODES.SCHEDULER}
          />
        )
      case 'payment-information':
        return (
          <PaymentInformation
            schedulerState={schedulerState}
            ref={ref}
            setSchedulerState={setSchedulerState}
            setDisabled={setNextDisabled}
            mode={MODES.SCHEDULER}
          />
        )
      case 'confirm-appointment':
        return (
          <ConfirmAppointment
            schedulerState={schedulerState}
            setSchedulerState={setSchedulerState}
            setDisabled={setNextDisabled}
            mode={MODES.SCHEDULER}
          />
        )
      default:
        return (
          <CustomerInformation
            schedulerState={schedulerState}
            setSchedulerState={setSchedulerState}
            ref={ref}
            setDisabled={setNextDisabled}
            mode={MODES.SCHEDULER}
          />
        )
    }
  }
  const resetState = () => {
    setSchedulerState(initialStateProps)
    setRouteIndex(0)
    setNextDisabled(true)
  }
  useEffect(() => {
    if (!props.isOpen) {
      resetState()
    }
  }, [props.isOpen])
  return (
    <div>
      <Modal
        open={props.isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={styles.scheduleModal}>
          <div className={`${styles.scheduleModalPageTitle} font--bold`}>
            {routes[routeIndex].title}
            {routes[routeIndex].title ===
              (routes[1].title || routes[6].title) && (
              <>
                <ServiceDropDown
                  mode={MODES.EDIT}
                  defaultValue={schedulerState.service}
                  onServiceChange={(newValue: string) => {
                    setSchedulerState((prevState) => ({
                      ...prevState,
                      service: newValue,
                    }))
                  }}
                />
                <ServicePriceOverride
                  schedulerState={schedulerState}
                  setSchedulerState={setSchedulerState}
                  priceOverrideIsDisabled={priceOverrideIsDisabled}
                  setPriceOverrideIsDisabled={setPriceOverrideIsDisabled}
                />
              </>
            )}
          </div>
          <div
            style={{
              maxHeight: 'calc(100% - 10rem)',
              width: '100%',
              overflowX: 'auto',
              overflowY: 'scroll',
            }}
          >
            {renderComponent(routes[routeIndex].path)}
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.flexGroupButtons}>
              <SecondaryButton
                disabled={routeIndex === 0}
                buttonName='Back'
                onClick={backClick}
              />
              <PrimaryButton
                buttonName='Cancel'
                onClick={onScheduleModalClose}
              />
            </div>
            {routeIndex !== 4 && (
              <PrimaryButton
                disabled={nextDisabled}
                buttonName='Next'
                onClick={forwardClick}
              />
            )}
            {routeIndex === 4 && (
              <PrimaryButton
                disabled={nextDisabled}
                buttonName='Confirm'
                onClick={confirmClick}
              />
            )}
          </div>
        </Box>
      </Modal>
    </div>
  )
}
