import { IFormDataProps } from '../customer-information/CustomerInformation'
import { ITimeSlot } from '../../../../../../../app/entities/TimeSlot'
import { AvailabilityCalendarSlot } from '../../../../../../../app/types/scheduling'
import { IOrderService } from '../../../../../../../app/entities/OrderService'
import { ITiresCombination } from '../../../../../../../app/entities/TiresCombination'

export interface ITiresProps {
  [key: string]: any
  frontCount: number
  rearCount: number
  frontTire: ITireProps
  rearTire: ITireProps
  objectId?: string | undefined
}

export interface ITireProps {
  aspectRatio: string
  width: string
  diameter: string
  objectId?: string | undefined
  manufacturer: string | null
  model: string | null
  psi: number | null
  torque: number | null
}

export interface ISchedulerStateProps {
  customerInformation: IFormDataProps
  tires: ITiresProps
  orderId: string
  orderVehicleId: string
  customerId: string
  customerVehicleId: string
  customerVehicleBrandId: string
  tireDisposal: boolean
  freeOrderReason: {
    objectId: string
    reason: string
    isFree: boolean
  }
  status: string
  service: string
  orderService: string
  disposalQty: number

  shouldRequestPaymentAfterScheduling: boolean

  selectedAvailabilityCalendarSlot?: AvailabilityCalendarSlot

  availableCalenderFromDateOverride?: number

  tiresCombination?: ITiresCombination

  genericItems?: IOrderService[]
  stateTireFee?: IOrderService
  servicePriceOverride?: number
  showAdditionalDates?: boolean
}

const initialCustomerInfoProps: IFormDataProps = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  address: '',
  aptOrSuite: '',
  city: '',
  state: '',
  zipCode: '',
  additionalInfo: '',
  make: '',
  model: '',
  year: '',
  variant: '',
  brandId: '',
  mileage: null,
}

const initialTireProps: ITiresProps = {
  frontCount: 2,
  rearCount: 2,
  frontTire: {
    aspectRatio: '',
    width: '',
    diameter: '',
    psi: null,
    manufacturer: '',
    model: '',
    torque: null,
  },
  rearTire: {
    aspectRatio: '',
    width: '',
    diameter: '',
    psi: null,
    manufacturer: '',
    model: '',
    torque: null,
  },
}

const initialTimeSlot: Partial<ITimeSlot> = {
  type: '',
  fromTime: new Date(),
  toTime: new Date(),
}

export const initialStateProps = {
  customerInformation: initialCustomerInfoProps,
  tires: initialTireProps,
  timeSlot: initialTimeSlot,
  orderId: '',
  orderVehicleId: '',
  customerId: '',
  customerVehicleId: '',
  customerVehicleBrandId: '',
  tireDisposal: true,
  disposalQty: 4,
  status: '',
  freeOrderReason: {
    objectId: '',
    reason: '',
    isFree: false,
  },
  service: '4068CCA8-EE44-4754-8764-61B9427DC68E',
  orderService: '',
  selectedSlotAvailabilityDetails: null,
  availableCalenderFromDateOverride: undefined,
  shouldRequestPaymentAfterScheduling: false,
  genericItems: [],
}
