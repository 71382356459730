import { useAuthenticator } from '@aws-amplify/ui-react'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import { fetchData } from '../../../../../global/utils/fetch'
import { uploadStateTireFees } from '../../../api'
import styles from '../styles.module.css'

export default function CsvUploads() {
  const { user } = useAuthenticator((context) => [context.user])

  async function onUploadStateTireFees(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const files = event.target.files

    console.log('files', files)

    if (!files) return

    const file = files[0]

    console.log('file', file)

    if (file) {
      const formData = new FormData()
      formData.append('file', file)

      await fetchData(uploadStateTireFees(formData))

      event.target.value = ''
    }
  }

  return (
    <div
      className={styles.csvUploadsContainer}
      style={{ marginTop: '2rem' }}
    >
      <div className={styles.pageTitle}>Csv Uploads</div>
      <div className={styles.csvUploadsContent}>
        <div className={styles.csvUploadsSection}>
          <div className={styles.sectionContent}>
            <div className={styles.uploadButton}>
              <input
                type='file'
                accept='.csv'
                id='csv-upload'
                style={{ display: 'none' }}
                onChange={onUploadStateTireFees}
                onClick={(event) => {
                  ;(event.target as any).value = null
                }}
              />
              <PrimaryButton
                buttonName='Upload State Tire Fees'
                onClick={() => {
                  const fileInput = document.querySelector('#csv-upload') as any

                  fileInput?.click()
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
