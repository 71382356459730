import styles from './styles.module.css'

function UserError() {
  return (
    <div>
      <div className={styles.userErrorScreen1}>
        <h2>Oops! Something went wrong.</h2>
      </div>
      <div className={styles.userErrorScreen}>
        Email is not associated to a user. Please reach out to an admin to
        resolve this issue.
      </div>
    </div>
  )
}
export default UserError
