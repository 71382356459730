import React, { useContext, useState } from 'react'
import UserEdit from './UserEdit'
import UserCreate from './UserCreate'
import styles from '../styles.module.css'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import SecondaryButton from '../../global/components/buttons/secondary-button/SecondaryButton'
import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import { IUserPartial } from '../../../../app/entities/User'
import PrimaryCheckbox from '../../global/components/checkbox/Checkbox'
import { fetchData } from '../../global/utils/fetch'
import { createNewUser, deleteUser, updateUser } from '../api'
import { ToastNotificationContext } from '../../global/context/toast-context/ToastNotificationContext'
import DeleteButton from '../../global/components/buttons/delete-button/DeleteButton'

interface IUserModalMainProps {
  email?: string
  isOpen: boolean
  handleClosed: Function
  setRefetchIndex: Function
  refetchIndex: number
}
export default function UserModalMain(props: IUserModalMainProps) {
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const { setRefetchIndex } = props
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formError, setFormError] = useState(false)
  const [userData, setUserData] = useState<IUserPartial>({
    objectId: '',
    name: '',
    email: '',
  })
  function handleCloseModal() {
    setUserData({
      objectId: '',
      name: '',
      email: '',
    })
    setFormError(false)
    setIsLoading(false)
    setIsChecked(false)
    props.handleClosed()
  }
  async function handleCreateUser() {
    const body = { ...userData }
    delete body.objectId
    try {
      setIsLoading(true)
      await fetchData(createNewUser(body))
      handleCloseModal()
      setRefetchIndex()
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'User successfully created!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error attempting to create user.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  async function handleUpdateUser() {
    try {
      setIsLoading(true)
      await fetchData(updateUser(userData))
      handleCloseModal()
      setRefetchIndex()
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'User info successfully updated!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error updating user.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  async function handleDeleteUser() {
    try {
      setIsLoading(true)
      await fetchData(deleteUser(userData.objectId!))
      handleCloseModal()
      setRefetchIndex()
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'User successfully deleted!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error attempting to delete user.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const disabledBtnLogic =
    !userData.email || !userData.role || !userData.name || formError

  return (
    <div>
      <Modal
        open={props.isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={styles.userModal}>
          <div className={`${styles.userModalTitle} font--bold`}>
            {props.email ? 'Update User' : 'Create User'}
          </div>
          <div className={styles.contentContainer}>
            {props.email ? (
              <UserEdit
                email={props.email}
                userData={userData}
                setUserData={setUserData}
                formError={formError}
                setFormError={setFormError}
              />
            ) : (
              <UserCreate
                userData={userData}
                setUserData={setUserData}
                formError={formError}
                setFormError={setFormError}
              />
            )}
          </div>
          {props.email && (
            <div className={styles.checkBoxContainer}>
              <PrimaryCheckbox
                checked={isChecked}
                onChange={(e) => {
                  setIsChecked(e.target.checked)
                }}
                label='Check this box to delete user'
              />
            </div>
          )}
          <div className={styles.footerButtons}>
            <div style={{ display: 'flex', gap: '8px' }}>
              <SecondaryButton
                buttonName='Cancel'
                onClick={() => handleCloseModal()}
              />
            </div>
            <div>
              {isChecked ? (
                <DeleteButton
                  buttonName='Delete'
                  disabled={isLoading}
                  onClick={handleDeleteUser}
                />
              ) : props.email ? (
                <PrimaryButton
                  buttonName='Save'
                  disabled={isLoading || disabledBtnLogic}
                  onClick={handleUpdateUser}
                />
              ) : (
                <PrimaryButton
                  buttonName='Create'
                  disabled={isLoading || disabledBtnLogic}
                  onClick={handleCreateUser}
                />
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
