export const width = [
  "155",
  "165",
  "175",
  "185",
  "195",
  "205",
  "215",
  "225",
  "235",
  "245",
  "255",
  "265",
  "275",
  "285",
  "295",
  "305",
  "315",
  "325",
  "335",
  "345",
  "355",
  "375",
  "395",
  "30X",
  "31X",
  "32X",
  "33X",
  "34X",
  "35X"
]

export const aspectRatio = [
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
  "60",
  "65",
  "70",
  "75",
  "80",
  "85",
  "7.5",
  "8.5",
  "10.5",
  "11.5",
  "12.5",
  "13.5"
]

export const diameter = [
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24"
]