import { ChangeEvent, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import styles from './styles.module.css'
import { IContactProps } from '../CustomerInformation'
import {
  isTenDigitPhoneNumber,
  isValidEmail,
} from '../../../../../../global/utils/formValidation'
import PrimaryButton from '../../../../../../global/components/buttons/primary-button/PrimaryButton'
import { MODES } from '../../../../../../global/constants/scheduler'

function ContactForm(
  props: IContactProps & { isEditDisabled: boolean; handleEdit: Function },
) {
  const [error, setError] = useState({
    email: false,
    phoneNumber: false,
  })
  const { formData, handleEdit, isEditDisabled, mode, setFormData } = props

  function updateLocationForm(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target
    if (name === 'email' && !isValidEmail(value)) {
      setError((prevState: any) => ({
        ...prevState,
        email: true,
      }))
    } else if (name === 'phone' && !isTenDigitPhoneNumber(value)) {
      setError((prevState: any) => ({
        ...prevState,
        phoneNumber: true,
      }))
    } else {
      setError({ ...error, email: false, phoneNumber: false })
    }
    setFormData({ ...formData, [name]: value })
  }

  return (
    <div className={styles.contactFormContainer}>
      <div className={`${styles.contactTitle} font--bold`}>Contact</div>
      <TextField
        disabled={!isEditDisabled}
        className={styles.textfieldStyles}
        type='text'
        label='First Name'
        name='firstName'
        value={formData.firstName}
        onChange={updateLocationForm}
      />
      <TextField
        disabled={!isEditDisabled}
        className={styles.textfieldStyles}
        type='text'
        label='Last Name'
        name='lastName'
        value={formData.lastName}
        onChange={updateLocationForm}
      />
      <TextField
        disabled={!isEditDisabled}
        className={styles.textfieldStyles}
        type='tel'
        label='Phone'
        name='phone'
        onChange={updateLocationForm}
        value={formData.phone}
        error={error.phoneNumber}
        inputProps={{ maxLength: 10 }}
        helperText={
          error.phoneNumber ? 'Please enter a valid 10-digit phone number' : ''
        }
      />
      <TextField
        disabled={!isEditDisabled}
        className={styles.textfieldStyles}
        type='email'
        label='Email'
        name='email'
        value={formData.email}
        onChange={updateLocationForm}
        error={error.email}
        helperText={error.email ? 'Please enter a valid email address' : ''}
      />
      {mode === MODES.CUSTOMER && (
        <PrimaryButton
          buttonName='Edit'
          disabled={isEditDisabled}
          onClick={handleEdit as any}
        />
      )}
    </div>
  )
}

export default ContactForm
