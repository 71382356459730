import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import styles from './styles.module.css'

interface ITorqueDropDownProps {
  handleDropDown: Function
  options: number[]
}
export default function TorqueDropDown(props: ITorqueDropDownProps) {
  const { handleDropDown, options } = props
  const reasonsMap = options.map((option: number, index: number) => {
      return (
      <MenuItem
        key={index}
        value={index}>
        {`${option}`}
      </MenuItem>
    )
  })

  return (
    <TextField
      defaultValue={0}
      className={styles.torqueDropDown}
      onChange={(e) => handleDropDown(e)}
      label="Torques"
      select
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        },
      }}>
      {reasonsMap}
    </TextField>
  )
}