import BasicFilter from '../../../../../../../global/components/table/helpers/BasicFilter'
import { BiReportModalDisplayModes } from '../../../../../../../global/constants/bi-reports'
import { ITrmiVanOperatingStatus } from '../../../../../../../../../bi/entities/TrmiVanOperatingStatus'

function generateTrmiVanOperatingStatusColumns(
  onColumnFilterUpdate: any,
  retrieveTableData: Function,
  generateTextField: Function,
  mode: BiReportModalDisplayModes,
  selectedRowIdx: number,
  handleChange: Function,
) {
  return [
    {
      Header: 'Van Name',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ vanName: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiVanOperatingStatus, rowIndex: number) => {
        return generateTextField(
          'vanName',
          data.vanName?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Vin',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ vin: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiVanOperatingStatus, rowIndex: number) => {
        return generateTextField(
          'vin',
          data.vin?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Operating Status',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ operatingStatus: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiVanOperatingStatus, rowIndex: number) => {
        return generateTextField(
          'operatingStatus',
          data.operatingStatus?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Operating Status Start Date',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ operatingStatusStartDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiVanOperatingStatus, rowIndex: number) => {
        return generateTextField(
          'operatingStatusStartDate',
          data.operatingStatusStartDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Operating Status End Date',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ operatingStatusEndDate: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiVanOperatingStatus, rowIndex: number) => {
        return generateTextField(
          'operatingStatusEndDate',
          data.operatingStatusEndDate?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Issue',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ issue: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiVanOperatingStatus, rowIndex: number) => {
        return generateTextField(
          'issue',
          data.issue?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
    {
      Header: 'Note',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ note: val }),
        onEnter: () => retrieveTableData(),
      }),
      accessor: (data: ITrmiVanOperatingStatus, rowIndex: number) => {
        return generateTextField(
          'note',
          data.note?.toString() ?? '',
          mode,
          rowIndex,
          selectedRowIdx,
          handleChange,
        )
      },
    },
  ]
}

export default generateTrmiVanOperatingStatusColumns
