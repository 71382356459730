const TITLES = {
  BI_REPORTS: 'BI Reports',
  CASES: 'Cases',
  CS_REPORTS: 'CS Reports',
  DASHBOARDS: 'Dashboards',
  HOLIDAYS: 'Holidays',
  LOCATIONS: 'Locations',
  PROMO_CODES: 'Promo Codes',
  QUEUES: 'Queues',
  SCHEDULE: 'Schedule',
  SERVICES: 'Services',
  TR_SALES: 'TR Sales',
  SMALL_PART_INVENTORY: 'Small Part Inventory',
  TECHNICIANS: 'Technicians',
  USER_MANAGEMENT: 'User Management',
  VANS: 'Vans',
  WORK_ORDERS: 'Work Orders',
}

export { TITLES }
