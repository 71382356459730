import styles from './styles.module.css'
import useFetch from '../../../../../global/hooks/useFetchHook'
import { toggleTireDisposal } from './api'
import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useMemo } from 'react'
import { MODES } from '../../../../../global/constants/scheduler'
import {
  ISchedulerStateProps,
  ITiresProps,
} from '../container-modal/initialData'
import { SERVICE_NAMES } from '../../../../../global/constants/service'
import { IOrderService } from '../../../../../../../app/entities/OrderService'

const FRONT = 'front'
const REAR = 'rear'

function formatTireSize(item: ITiresProps) {
  const { frontTire, rearTire } = item
  return {
    frontSize: `${frontTire.width} | ${frontTire.aspectRatio} R${frontTire.diameter}`,
    rearSize: `${rearTire.width} | ${rearTire.aspectRatio} R${rearTire.diameter}`,
  }
}
function returnLoadingOrPrice(
  loading: boolean,
  data: any,
  serviceObjectId: string,
  tires?: ITiresProps,
  frontOrRear?: string,
) {
  if (loading || !data) {
    return <CircularProgress size={20} />
  } else {
    let orderService: IOrderService | null | any = null

    if (data.servicesInfo && Array.isArray(data.servicesInfo)) {
      data.servicesInfo.forEach((i: any) => {
        if ([i.service.objectId, i.label].includes(serviceObjectId)) {
          orderService = i as IOrderService
        }
      })
    }

    if (!orderService) {
      return '$0.00'
    }

    let finalPrice = 0

    if (orderService.priceOverride || orderService.priceOverride === 0) {
      finalPrice = orderService.priceOverride
    } else if (orderService.locationService) {
      const { price } = orderService.locationService
      finalPrice = price || 0
    } else {
      const { price } = orderService
      finalPrice = price || 0
    }

    const { frontCount, rearCount } = tires || { frontCount: 0, rearCount: 0 }

    const shouldSetFrontPriceToZero =
      frontOrRear === FRONT && !Boolean(frontCount)
    if (shouldSetFrontPriceToZero) {
      finalPrice = 0
    }

    const shouldSetRearPriceToZero = frontOrRear === REAR && !Boolean(rearCount)
    if (shouldSetRearPriceToZero) {
      finalPrice = 0
    }

    return toDisplayPrice(finalPrice)
  }
}

function toDisplayPrice(price: number) {
  const roundedPrice = price ? price.toFixed(2) : '0.00'
  return `$${roundedPrice}`
}

function returnLoadingOrTotal(loading: boolean, data: any) {
  if (loading || !data) {
    return <CircularProgress size={20} />
  }
  return `$${data.estimatedTotal}`
}

export default function OrderSummary({
  schedulerState,
  mode,
  toggleToastError,
}: {
  schedulerState: ISchedulerStateProps
  mode: string
  toggleToastError?: Function
}) {
  const {
    tires,
    orderId,
    tireDisposal,
    orderVehicleId,
    freeOrderReason,
    disposalQty,
  } = schedulerState
  const tire = formatTireSize(tires)
  const toggleDisposal = useMemo(() => {
    return toggleTireDisposal(
      orderId,
      orderVehicleId,
      tireDisposal,
      disposalQty,
    )
  }, [orderId, orderVehicleId, tireDisposal, disposalQty])

  let { data: servicesData, isLoading, error } = useFetch<any>(toggleDisposal)

  const minimumServiceCharge = useMemo(() => {
    const minimumServiceCharge = servicesData?.servicesInfo?.find(
      (service: any) => service.label === SERVICE_NAMES.MINIMUM_SERVICE_CHARGE,
    )

    return minimumServiceCharge?.priceOverride
      ? toDisplayPrice(minimumServiceCharge.priceOverride)
      : null
  }, [servicesData])

  const showAdditionalServices = useMemo(() => {
    return !!schedulerState.genericItems?.length
  }, [schedulerState.genericItems])

  useEffect(() => {
    if (error) {
      if (mode === MODES.CUSTOMER) {
        toggleToastError && toggleToastError(true)
      } else {
        alert(`Error With tire-disposal Api call: ${error}`)
      }
    }
  }, [error, schedulerState])

  // during TR Sales, an empty JSX element is passed
  if (mode === MODES.TR_SALES_BOOKING) {
    return <div></div>
  }

  return (
    <div className={styles.orderSummaryContainer}>
      <div className={styles.orderSummaryLabel}>Order Summary</div>
      <div className={styles.breakdownsContainer}>
        <div className={styles.breakdown}>
          <div className={styles.breakdownTitle}>Service Summary</div>
          <div>Tire Installation</div>
        </div>
        <div className={styles.breakdown}>
          <div className={styles.breakdownTitle}>Tire Summary</div>
          <div className={styles.breakdownContent}>
            <div className={styles.tireSummaryContainer}>
              <div className={styles.orderServiceRow}>
                <div className='font--bold'>{tire.frontSize}</div>
                <div className='font--bold'>{tires.frontCount}</div>
                <div>
                  {freeOrderReason?.isFree
                    ? '$0.00'
                    : returnLoadingOrPrice(
                        isLoading,
                        servicesData,
                        schedulerState.service,
                        tires,
                        FRONT,
                      )}
                </div>
              </div>
              <div className={styles.orderServiceRow}>
                <div className='font--bold'>{tire.rearSize}</div>
                <div className='font--bold'>{tires.rearCount}</div>
                <div>
                  {freeOrderReason?.isFree
                    ? '$0.00'
                    : returnLoadingOrPrice(
                        isLoading,
                        servicesData,
                        schedulerState.service,
                        tires,
                        REAR,
                      )}
                </div>
              </div>
              <div className={styles.orderServiceRow}>
                <div className='font--bold'>Tire Disposal</div>
                <div className='font--bold'>{disposalQty}</div>
                <div>
                  {freeOrderReason?.isFree
                    ? '$0.00'
                    : returnLoadingOrPrice(
                        isLoading,
                        servicesData,
                        SERVICE_NAMES.TIRE_DISPOSAL,
                      )}
                </div>
              </div>
              {minimumServiceCharge && (
                <div className={styles.orderServiceRow}>
                  <div className='font--bold'>Minimum Service Charge</div>
                  <div className='font--bold'>{''}</div>
                  <div>{minimumServiceCharge}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showAdditionalServices && (
          <div className={styles.breakdown}>
            <div className={styles.breakdownTitle}>Additional Services</div>
            {schedulerState.genericItems?.map((item: IOrderService) => (
              <div
                key={item.objectId}
                className={styles.orderServiceRow}
              >
                <div>
                  {item.label}
                  {item.description ? `: ${item.description}` : ''}
                </div>
                <div>{item.quantity}</div>
                <div>
                  {freeOrderReason?.isFree
                    ? '$0.00'
                    : toDisplayPrice(item.price)}
                </div>
              </div>
            ))}
            {schedulerState.stateTireFee && (
              <div className={styles.orderServiceRow}>
                <div>{schedulerState.stateTireFee.label}</div>
                <div>{schedulerState.stateTireFee.quantity}</div>
                <div>
                  {freeOrderReason?.isFree
                    ? '$0.00'
                    : toDisplayPrice(schedulerState.stateTireFee.price)}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.estimatedTotalContainer}>
        <div className={styles.estimatedTotalLabel}>Estimated Total</div>
        <div className={styles.estimatedTotalPrice}>
          {freeOrderReason?.isFree
            ? '$0.00'
            : returnLoadingOrTotal(isLoading, servicesData)}
        </div>
      </div>
    </div>
  )
}
