import styles from './styles.module.css'
import VirTable from './vir-table/VirTable'
import { VIR_FREQUENCY } from '../../../../global/constants/virs'
import EditVanFormComponent from '../../van-form-component/edit/EditVanFormComponent'

interface IEditVanContentContainerProps {
  setIsFormDataValid: Function
  setOperatingStatusNote: Function
}

function EditVanContentContainer({
  setIsFormDataValid,
  setOperatingStatusNote,
}: IEditVanContentContainerProps) {
  return (
    <div className={styles.contentContainer}>
      <EditVanFormComponent
        setIsFormDataValid={setIsFormDataValid}
        setOperatingStatusNote={setOperatingStatusNote}
      />
      <div className={styles.tableContainer}>
        <div>
          <VirTable frequency={VIR_FREQUENCY.DAILY} />
        </div>
        <div>
          <VirTable frequency={VIR_FREQUENCY.WEEKLY} />
        </div>
      </div>
    </div>
  )
}

export default EditVanContentContainer
